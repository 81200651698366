import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
// material
import {
  Avatar,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  CardHeader, Button
} from '@material-ui/core';
import { Autocomplete, LoadingButton } from '@material-ui/lab';
import AirplayIcon from "@material-ui/icons/Airplay";
import moment from "moment";
import Box from '@mui/material/Box';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../components/_dashboard/user';
//
import { fetchRecord, patchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { SPENDERS, USER } from '../constants/ApiEndPoint';
import { SPENDERS_ROUTES, DASHBOARD_ROUTES } from '../constants/SiteRoutes';
import { prepareQueryParams } from '../utils/General';
import { setFlash } from '../stores/actions/Flash';
import { setModal } from '../stores/actions/Modal';
import Label from "../components/Label";
import PlayMedia from "../components/PlayMedia";

import {DATE_FORMAT_WITH_TIME} from "../constants";


// ----------------------------------------------------------------------

export default function Spenders() {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter, setFilter] = useState('all');
  const [update, setUpdate] = useState(false);
  const [recentSpenders, setRecentSpenders] = useState(pathName === DASHBOARD_ROUTES.TRANSACTIONS);
  // const [recentSpenders, setRecentSpenders] = useState(pathName === SPENDERS_ROUTES.RECENT);
  const [creatorsLoader, setCreatorsLoader] = useState(false);
  const [type, setType] = useState('free');
  const [value, setValue] = React.useState([0,0]);
  const options = [
    { value: 'free', label: 'Free' }
  ];

  const [fromRange, setFromRange] = useState(0);
  const [toRange, setToRange] = useState(0);

  const TABLE_HEAD = [
    { id: 'displayName', label: 'Display Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'total', label: 'Total', alignRight: false },
    { id: 'ccbillPaymentCode', label: 'CCBill Payment Code', alignRight: false },
  ];

  if(recentSpenders){
    TABLE_HEAD.push({ id: 'type', label: 'Type', alignRight: false });
    TABLE_HEAD.push({ id: 'paidTo', label: 'Creator', alignRight: false });
  }

  TABLE_HEAD.push({ id: 'createdAt', label: 'Created At', alignRight: false });

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
    setEmptyRows(eRows);
  }, [page]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, 'newPage');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };

  useEffect(() => {

    setRecentSpenders(pathName === DASHBOARD_ROUTES.TRANSACTIONS);
    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const [creators, setCreators] = useState([]);
  const [creator, setCreator] = useState('');

  const listCreators = async () => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      sortColumn: 'displayName',
      sortOrder: order,
      searchText: filterName,
      creators: true,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200 && response.docs && response.data.docs) {
        setCreators(response.data.docs);
      }
      setIsLoading(false);
    }
  };

  const onInputChange = async (event, value) => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      creators: true,
      filter
    };
    if (event && (event.type === 'click' || event.key === 'Enter')) {
      value = '';
    }
    queryParams.searchText = value;
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response.status === 200) {
      setCreators(response.data.docs);
    }
  };

  useEffect(() => {
    listCreators().then().catch().finally();
  }, []);

  const listUsers = async () => {
    const queryParams = {
      page: page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      recentSpenders,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(recentSpenders ? SPENDERS.RECENT : SPENDERS.TOP, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setUsers(response.data.docs);
        setTotalDocs(response.data.totalDocs);
        setToRange(response.data.totalDocs);

      } else {
        setUsers([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listUsers().then().catch().finally();
  }, [
    page, filter, update
  ]);

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'creators', label: 'Creators' }
  ];

  const onChange = (event,value) => {

    setCreator(value);
  };

  const handleSubmit = async () => {
    // console.log(value,value[0],value[1])
    // if(value[1] === 0){
    //   dispatch(setModal({status:401,message:'Please select users range'}));
    //   return;
    // }

    // const body = {
    //   creator:creator._id,
    //   range: [fromRange, toRange],
    //   type: pathName === SPENDERS_ROUTES.RECENT ? 'recent' : 'top'
    // };

    const body = {
      creator: creator._id,
      range: [fromRange, toRange],
      type: 'allUsers',
      customers:true,
      searchText : ''
    };
    setIsLoading(true);
    const response = await patchRecord(SPENDERS.ADD_SUBSCRIBERS, body, '');
    setIsLoading(false);
    if(response){
      setCreator(null);
      dispatch(setFlash(response));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}`;
  }

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  const handleMedia = (media) => {
    setMedia(media);
    setOpen(true);
  };

  return (
    <Page title="Transactions">
      <Container>
        {loading && <SimpleBackdrop/>}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
        </Stack>
        <Stack style={{ marginBottom: 20 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h6" gutterBottom>
              Add Subscribers
            </Typography>
          </Stack>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography gutterBottom>Transactions Range</Typography>
                <Box>
                  <TextField label="From" variant="outlined" InputLabelProps={{ shrink: true, }} style={{width: "80px"}} value={fromRange} onChange={(e) => {setFromRange(e.target.value);}}/>
                  <TextField label="To" variant="outlined" InputLabelProps={{ shrink: true, }} style={{width: "80px", marginLeft: 10}} value={toRange} onChange={(e) => {setToRange(e.target.value);}}/>
                </Box>
              </Grid>
              <Grid item xs={4} style={{marginTop: "30px"}}>
                <Autocomplete
                  id="creator"
                  name="creator"
                  value={creator}
                  onInputChange={onInputChange}
                  onChange={(event,value) => setCreator(value)}
                  options={creators}
                  getOptionLabel={option =>
                    option.displayName ? option.displayName : option
                  }
                  renderInput={params => (
                    <TextValidator
                      {...params}
                      variant="outlined"
                      fullWidth
                      label="Creator"
                      placeholder="Creator"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={creator && creator.displayName}
                      validators={['required']}
                      errorMessages={['Creator is required.']}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {creatorsLoader ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} style={{marginTop: "30px"}}>
                <TextField
                  select
                  value={type}
                  onChange={onChange}
                  fullWidth
                  variant="outlined"
                  label="Type"
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2} style={{marginTop: "30px"}}>
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Add Subscribers
                </LoadingButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Stack>
        <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth:900 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, total, user, userInfo,type,mediaFiles,createdAt,creatorInfo, currency, ccbillPaymentCode } = row;
                    const isItemSelected = selected.indexOf(userInfo.username) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
       
                        <TableCell component="th" scope="row" padding="normal">
                          <CardHeader
                            style={{padding:0}}
                            avatar={<Avatar alt={userInfo.displayName} src={userInfo.photoUri} />}
                            title={
                              <Typography variant="subtitle2" noWrap>
                                {userInfo.displayName}
                              </Typography>
                            }
                            subheader={userInfo.username}
                            
                          />
                          {/* <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={userInfo.displayName} src={userInfo.photoUri}/>
                            <Typography variant="subtitle2" noWrap>
                              {userInfo.displayName}
                            </Typography>
                          </Stack> */}
                        </TableCell>
                        <TableCell align="left">{userInfo.email}</TableCell>
                        <TableCell align="left">
                          {currency.toUpperCase()} {total / 100}
                        </TableCell>
                        <TableCell align="left">
                          {ccbillPaymentCode}
                        </TableCell>
                        {recentSpenders &&
                          <>
                            <TableCell align="left">
                              <Label variant="ghost" color="success">
                                {type}
                              </Label>
                              {mediaFiles && mediaFiles.length > 0 &&
                                <div style={{
                                  maxHeight: 145,
                                  overflowY: 'scroll'
                                }}>
                                  {mediaFiles.map(media => (
                                    <Button key={media._id} onClick={() => handleMedia(media)}>
                                      <AirplayIcon />
                                    </Button>
                                  ))}
                                </div>
                              }
                            </TableCell>
                            <TableCell align="left">
                              {creatorInfo && <CardHeader
                                style={{padding:0}}
                                avatar={<Avatar alt={creatorInfo.displayName} src={creatorInfo.photoUri} />}
                                title={
                                  <Typography variant="subtitle2" noWrap>
                                    {creatorInfo.displayName}
                                  </Typography>
                                }
                                subheader={creatorInfo.username}

                              />
                              }
                            </TableCell>
                          </>

                        }
                        <TableCell align="left">
                          <Label variant="ghost" color="info">
                            {moment(createdAt).format(DATE_FORMAT_WITH_TIME)}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6}/>
                    </TableRow>
                  )}
                </TableBody>
                {users.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 200, 500]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {open &&
          <PlayMedia
            media={media}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
          />
        }
      </Container>
    </Page>
  );
}
