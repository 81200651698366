const Configs = {
  API_URI: process.env.REACT_APP_API_URL,
  APP_URL: process.env.REACT_APP_URL,
  AWS :{
    BUCKET: process.env.REACT_APP_AWS_BUCKET,
    REGION: process.env.REACT_APP_AWS_REGIONS,
    ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    BUCKET_MP4: process.env.REACT_APP_AWS_BUCKET_MP4
  },
  FIREBASE_MESSAGING :{
    apiKey: process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_MESSAGING_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_MESSAGING_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_MESSAGING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_MESSAGING_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID,
    publicKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
  },
  ENABLE_FCM : (process.env.REACT_APP_ENABLE_FCM && process.env.REACT_APP_ENABLE_FCM === "YES"),
  ENABLE_GTM: process.env.REACT_APP_GTM_ID && process.env.REACT_APP_GTM_ID.length > 0,
  GTM_ID : process.env.REACT_APP_GTM_ID
}


export default Configs;
