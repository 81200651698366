import { useLocation } from 'react-router';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ReferralListHead, ReferralListToolbar, ReferralMoreMenu } from '../components/_dashboard/referrals';
//
import USERLIST from '../_mocks_/user';
import { fetchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { REFERRALS, USER } from '../constants/ApiEndPoint';
import { USER_ACTIVE } from '../constants';
import { USER_ROUTES } from '../constants/SiteRoutes';
import { prepareQueryParams } from '../utils/General';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'userInfo', label: 'Referrer', alignRight: false },
  { id: 'subscriber', label: 'Subscriber', alignRight: false },
  { id: 'creator', label: 'Creator', alignRight: false },
  { id: 'stripeTransferId', label: 'Stripe Account', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'net', label: 'Net', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const location  = useLocation();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading,setIsLoading] = useState(false);
  const [rows,setRows] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter,setFilter] = useState('unPaid');
  const [update,setUpdate] = useState(false);


  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(eRows);
  }, [page,rowsPerPage]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => { console.log(newPage,'newPage')
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };

  useEffect(() => {

    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const listRows = async () => {
    const queryParams = {
      page : page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(REFERRALS.LIST,'','',prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setRows(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setRows([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listRows().then().catch().finally();
  }, [
    page,filter,update
  ]);


  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'paid', label: 'Paid' },
    { value: 'unPaid', label: 'Unpaid' },
  ];

  return (
    <Page title="Referrals">
      <Container>
        {loading && <SimpleBackdrop />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Referrals
          </Typography>
         {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <ReferralListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterOptions={filterOptions}
            filter={filter}
            setFilter={setFilter}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ReferralListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {rows.map((row) => {
                      const { _id,amount,createdAt, status,stripeAccountId,userInfo,creatorInfo,subscriberInfo,net} = row;
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                        >
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={userInfo.displayName} src={userInfo.photoUri} />
                              <Typography variant="subtitle2" noWrap>
                                {userInfo.displayName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={subscriberInfo.displayName} src={subscriberInfo.photoUri} />
                              <Typography variant="subtitle2" noWrap>
                                {subscriberInfo.displayName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={creatorInfo.displayName} src={creatorInfo.photoUri} />
                              <Typography variant="subtitle2" noWrap>
                                {creatorInfo.displayName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {stripeAccountId}
                          </TableCell>
                          <TableCell align="left">
                            {createdAt}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color='success'
                            >
                              ${net/100}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={status ? 'success' : 'error'}
                            >
                              {status && 'Paid'}
                              {!status && 'UnPaid'}
                            </Label>
                          </TableCell>


                          <TableCell align="right">
                            <ReferralMoreMenu
                              _id={_id}
                              setIsLoading={setIsLoading}
                              isVerifiedByAdmin={false}
                              update={update}
                              status={status}
                              setUpdate={setUpdate}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {rows.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
