import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import {clearModal} from "../../stores/actions/Modal";

export default function MessageModal() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

/*  const handleClose = () => {
    setOpen(false);
  }; */

  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);

  const handleClose = (event, reason) => {
    dispatch(clearModal());
    if (reason === 'clickaway') {
      return '';
    }

  };
  const message = modal ? modal.message : false;

  if (!message) {
    return '';
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
       { /* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */ }
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
