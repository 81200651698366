import { SET_MODAL,CLEAR_MODAL } from "../../actions/Modal";
import {GENERAL} from "../../../constants/ApiResponseFormat";

const _body = GENERAL;
_body.message = 'Something went wrong.'
export default (state = null, { body, type }) => {
  Object.freeze(state);
  switch (type) {
    case SET_MODAL:
      return body ||_body;
    case CLEAR_MODAL:
      return null;
    default:
      return state;
  }
};
