import React, { useState,forwardRef } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { clearFlash } from '../../stores/actions/Flash';


/* function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
} */

function AlertComponent(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
}

const Alert = forwardRef(AlertComponent);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    '& .MuiAlert-filledWarning': {
      background: '#f44336'
    }
  }
}));

/**
 * @return {string}
 * @return {string}
 */

export default function FlashMessage(props) {
  const flash = useSelector(state => state.flash);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    dispatch(clearFlash());
    if (reason === 'clickaway') {
      return;
    }
    if (props.onClose) {
      props.onClose();
    }

    // setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  if (flash === null) {
    return '';
  }

  let { severity } = props;
  const message = flash ? flash.message : false;
  const status = flash ? flash.status : 200;
  const autoHideDuration =
    flash && flash.autoHideDuration ? flash.autoHideDuration : 3000;

  if (status === 200) {
    severity = 'success';
  } else if (status === 500 || status === 503) {
    severity = 'warning';
  } else {
    severity = 'error';
  }
  if (!message) {
    return '';
  }

  return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
  );
}
