import React from 'react';
import { useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { verifyCode } from '../../../stores/actions/Authorize';
import ClearNotify from '../../common/ClearNotify';
import Notify from '../../common/Notify';
import SimpleBackdrop from '../../common/SimpleBackdrop';
import { DASHBOARD_ROUTES } from '../../../constants/SiteRoutes';

export default function VerifyForm() {
  const dispatch = useDispatch();

  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit(event) {
    const user = { code, email: localStorage.getItem('admin_email') };
    setLoading(true);
    const response = await dispatch(verifyCode(user));
    if (response) {
      if (response.status === 402) {
        setLoading(false);
      } else if (response.status === 200) {
        window.location.href = DASHBOARD_ROUTES.STATS;
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <SimpleBackdrop />}
      <ValidatorForm onSubmit={handleSubmit}>
        <ClearNotify />
        <Notify />

        <Stack spacing={3}>
          <TextValidator
            label="Code"
            onChange={(e) => setCode(e.target.value)}
            placeholder="Code"
            helperText=""
            fullWidth
            name="code"
            type="text"
            margin="normal"
            value={code}
            errorMessages={['Email field is required.']}
            autoFocus
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Verify
        </LoadingButton>
      </ValidatorForm>
    </>
  );
}
