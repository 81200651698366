import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTheme} from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import {Typography} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import moment from "moment";
import {postRecord} from '../../../services/Axios';
import {USER} from '../../../constants/ApiEndPoint';
import {setFlash} from '../../../stores/actions/Flash';
import {uploadToS3} from "../../../services/AwsService";
import EarningStatics from "./EarningStatics";


const useStyles = makeStyles(theme => ({
  mediaWrapper: {
    margin: 10
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#fff !important'
  },

}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Earnings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {open, setOpen, userId, setUpdate, update, setIsLoading, user} = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Earnings of <Typography variant="body2" color="green" style={{display: 'inline'}}>{user.displayName} ({user.username})</Typography></DialogTitle>
        <DialogContent className={classes.mediaWrapper}>
          <div className={classes.root}>
            <AppBar position="static" style={{backgroundColor:'white'}}>
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Stats" {...a11yProps(0)} />
            {/*    <Tab label="Item Two" {...a11yProps(1)} />
                <Tab label="Item Three" {...a11yProps(2)} /> */}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <EarningStatics userId={userId}  setIsLoading={setIsLoading} />
            </TabPanel>
           {/* <TabPanel value={value} index={1}>
              Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
              Item Three
            </TabPanel> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
