import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled, } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton,Badge } from '@material-ui/core';
import {useSelector} from "react-redux";
import './NavSection.css';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  badge: {
    '& .MuiBadge-root': {
      right: 'auto',
    },
    '& .MuiBadge-badge': {
      right: 'auto',
    },
  },
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const classes = useStyles();
  const user = useSelector(state => state.authorize);
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children,hasBadge,badgeKey } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  const getBadgeContent = (badgeKey) => {
    if(badgeKey === 'bankingRequestCount'){
      return user.bankingRequestCount;
    }
    if(badgeKey === 'payoutRequestCount'){
      return user.payoutRequestCount;
    }
    if(badgeKey === 'newCreatorRequestsCount') {
      return user.newCreatorRequestsCount;
    }
    if(badgeKey === 'reportedPostCount') {
      return user.reportedPostCount;
    }
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {hasBadge && <Badge badgeContent={getBadgeContent(badgeKey)} max={10000} color="error" className={classes.badge}>
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          </Badge>}
          {!hasBadge && <ListItemIconStyle>{icon && icon}</ListItemIconStyle>}
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, hasBadge, badgeKey } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  {hasBadge &&
                    <Badge badgeContent={getBadgeContent(badgeKey)} max={10000} color="error" className='children-badge'>
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main'
                            })
                          }}
                        />
                      </ListItemIconStyle>
                    </Badge>}
                    {!hasBadge && <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main'
                            })
                          }}
                        />
                      </ListItemIconStyle>}
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      {hasBadge &&
        <Badge badgeContent={getBadgeContent(badgeKey)} color="error" className={classes.badge}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </Badge>}
      {!hasBadge && <ListItemIconStyle>{icon && icon}</ListItemIconStyle>}
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            active={match}
            children={item.children}
            hasBadge={item.hasBadge}
            badgeKey={item.badgeKey}
          />
        ))}
      </List>
    </Box>
  );
}
