import React,{ useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { verifyUser } from '../../../stores/actions/Authorize';
import { setNotify } from '../../../stores/actions/Notify';
import ClearNotify from '../../common/ClearNotify';
import Notify from '../../common/Notify';
import SimpleBackdrop from '../../common/SimpleBackdrop';
import { AUTH_ROUTES } from '../../../constants/SiteRoutes';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [remember, setRemember] = React.useState(false);

  async function handleSubmit(event) {
    const user = { email, password };
    setLoading(true);
    const response = await dispatch(verifyUser(user));
    if (response) {
      if (response.status === 402) {
        setLoading(false);
      } else if (response.status === 200) {
        localStorage.setItem('admin_email', email);
        window.location.href = '/verify-code';
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <SimpleBackdrop />}
      <ValidatorForm onSubmit={handleSubmit}>
        <ClearNotify />
        <Notify />

        <Stack spacing={3}>
          <TextValidator
            label="E-mail"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
            helperText=""
            fullWidth
            name="email"
            type="text"
            margin="normal"
            value={email}
            validators={['required', 'isEmail']}
            errorMessages={['Email field is required.', 'Must be a valid Email.']}
            // variant="outlined"
            autoComplete="email"
            autoFocus
          />
          {/*       <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          /> */}

          {/*        <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}

          <TextValidator
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            helperText=""
            fullWidth
            name="password"
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            value={password}
            validators={['required']}
            errorMessages={['Password is required.']}
            // variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={<Checkbox checked={remember} />} label="Remember me" />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          Login
        </LoadingButton>
      </ValidatorForm>
    </>
  );
}
