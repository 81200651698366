import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkMarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import alertCircleOutline from '@iconify/icons-eva/alert-circle-outline';
import personDeleteFill from '@iconify/icons-eva/person-delete-fill';
import personDoneOutline from '@iconify/icons-eva/person-done-outline';
import personDeleteOutline from '@iconify/icons-eva/person-delete-outline';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { fetchRecord, patchRecord } from '../../../services/Axios';
import { POST, USER } from '../../../constants/ApiEndPoint';
import { POST_ACTIVE, USER_ACTIVE } from '../../../constants';

// ----------------------------------------------------------------------

export default function PostMoreMenu({postId,setIsLoading,update,setUpdate,status,deletedAt, isReviewed=false}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);


  const block = async (block= true) => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(block ? POST.BLOCK : POST.UNBLOCK,postId,'');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const deletePost = async (deleting = true) => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(deleting ? POST.DELETE_POST : POST.RESTORE,postId,'');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const markPostReviewed = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await patchRecord(POST.MARK_REVIEWED, { postId });
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 240, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        <MenuItem  sx={{ color: 'text.secondary' }} onClick={() => status === POST_ACTIVE ? block(true) : block(false) } >
          <ListItemIcon>
            <Icon icon={status === POST_ACTIVE ? personDeleteOutline  : personDoneOutline } width={24} height={24}  color={status === POST_ACTIVE ? "#B72136 " : "#229a16" }/>
          </ListItemIcon>
          <ListItemText primary={status === POST_ACTIVE ? "Block" : "Allow" } primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem  sx={{ color: 'text.secondary' }} onClick={() => deletedAt ? deletePost(false) : deletePost(true) } >
          <ListItemIcon>
            <Icon icon={deletedAt ? refreshOutline  : closeCircleFill } width={24} height={24}  color={deletedAt ?  "#229a16":"#B72136 " }/>
          </ListItemIcon>
          <ListItemText primary={deletedAt ? "Restore" : "Delete" } primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {status && !isReviewed && (<MenuItem  sx={{ color: 'text.secondary' }} onClick={() => markPostReviewed() } >
            <ListItemIcon>
              <Icon icon={closeCircleFill } width={24} height={24}  color="#229a16" />
            </ListItemIcon>
            <ListItemText primary="Reviewed & Not Rejected" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
