const AUTH_CODES = {
  SUCCESS: 200,
  ACCOUNT_NOT_VERIFIED : 402,
  SET_ACCOUNT_PASSWORD: 411,
}

export const COMMON_CODES = {
  SUCCESS: 200,
  DUPLICATE: 409,
  NO_RECORDS: 204,

}

export {
  AUTH_CODES,
}
