export const SET_NOTIFY = 'SET_NOTIFY';
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY';

export const setNotify = (body) => (
  {
    type: SET_NOTIFY,
    body
  }
);

export const clearNotify = () => ({
  type: CLEAR_NOTIFY
});
