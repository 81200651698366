import React, {useState } from 'react';
import {CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getMp4Uri } from '../../services/GeneralService';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },

    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6)
    },

}));

export default function Thumbnail(props) {
    const classes = useStyles();
    const [url, setUrl] = useState(props.url);
    const [type, setType] = useState(props.type);
    return (
        <>
            {type === 'VIDEO' &&
                <CardMedia
                    component="video"
                    controls
                    src={getMp4Uri(url)}
                />
            }
            {type === 'PHOTO' &&
                <CardMedia
                    component="img"
                    image={url}
                    alt="Image"
                    height="140"
                    title="Image"
                />
            }

        </>
    );
}
