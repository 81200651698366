export const prepareQueryParams = (queryParams) => {
  let str = '';
  Object.entries(queryParams).forEach((item,index) =>{
    const param = item && item[0] && item[1];
    if (str !== '' && param) {
      str += '&';
    }
    str += param ? `${item[0]}=${encodeURIComponent(item[1])}` : '';
  });

  return str;
};