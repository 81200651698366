import React, { useEffect, useState } from 'react';
import { Card, CardContent, Container, Stack, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import draftToHtml from 'draftjs-to-html';
import { LoadingButton } from '@material-ui/lab';
import Page from '../components/Page';
import { fetchRecord, patchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { BANNED_WORDS, PAGE } from '../constants/ApiEndPoint';

const useStyles = makeStyles((theme) => ({
  demoEditorCustom: {
    height: '275px !important',
    border: '1px solid #F1F1F1 !important',
    padding: '5px !important',
    borderRadius: '2px !important',
    width: '100% !important',
    resize: 'none !important',
  },
  backdropWrap: {
    position: 'relative'
  }
}));

export default function BannedWords() {
    const classes = useStyles();
    const [loading, setIsLoading] = useState(false);
    const [words, setWords] = useState('');
    const onChange = (e) => {
        setWords(e.target.value);
    };

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        setIsLoading(true);
        const response = await fetchRecord(BANNED_WORDS.GET);
        console.log(response);
        if (response.data) {
            let wordlist = '';
            response.data.forEach((word, index) => {
                if (index === response.data.length - 1) {
                    wordlist+=`${word.word}`;
                } else {
                    wordlist+=`${word.word}, `;
                }
            });
            setWords(wordlist);
        }
        setIsLoading(false);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        await patchRecord(BANNED_WORDS.SAVE, { words });
        setIsLoading(false);
    };

    return (
        <Page title="Banned Words">
            <Container>
                {loading && <SimpleBackdrop />}
                <Card>
                    <CardContent>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h4" gutterBottom>
                                Banned Words
                            </Typography>
                        </Stack>
                        <ValidatorForm onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <textarea
                                    className={classes.demoEditorCustom}
                                    value={words}
                                    onChange={onChange}
                                    required
                                    placeholder='Enter banned words separated by comma (,)'
                                />
                                <LoadingButton size="medium" type="submit" variant="contained" loading={loading}>
                                    Save
                                </LoadingButton>
                            </Stack>
                        </ValidatorForm>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}
