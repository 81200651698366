import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Posts from './pages/Posts';
import Blog from './pages/Blog';
import User from './pages/User';
import POST from './pages/Post';
import NotFound from './pages/Page404';
import { DASHBOARD_ROUTES, POST_ROUTES, SPENDERS_ROUTES, USER_ROUTES } from './constants/SiteRoutes';
import Referrals from './pages/Referrals';
import Spenders from './pages/Spenders';
import Content from './pages/Content';
import Fans from './pages/Fans';
import PlatformFees from './pages/PlatformFees';
import PlatformFeesInfos from './pages/PlatformFeesInfos';
import PlatformUsers from './pages/PlatformUsers';
import MassMessages from './pages/MassMessages';
import Medias from './pages/Medias';
import Reviews from "./pages/Reviews";
import Payouts from "./pages/Payouts";
import Transactions from "./pages/Transactions";
import Masspay from "./pages/Masspay";
import MasspayExport from './pages/MasspayExport';
import VerifyOtp from './pages/VerifyOtp';
import FavouriteUser from './pages/FavouriteUsers';
import BannedWords from './pages/BannedWords';
import UserBannedWords from './pages/UserBannedWords';

// ----------------------------------------------------------------------

export default function Router(props) {
  console.log();
  const user = useSelector((state) => state.authorize);
  const isLoggedIn = Boolean(user._id);
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn ? <Navigate to={DASHBOARD_ROUTES.STATS} /> : <Login />
        },
        {
          path: 'verify-code',
          element: isLoggedIn ? <Navigate to={DASHBOARD_ROUTES.STATS} /> : <VerifyOtp />
        },
        { path: 'register', element: <Register /> },
        { path: 'forgot-password', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '/users', element: <Navigate to="/users" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to={DASHBOARD_ROUTES.STATS} replace /> },
        { path: DASHBOARD_ROUTES.STATS_INDEX, element: <DashboardApp /> },
        { path: DASHBOARD_ROUTES.POST_INDEX, element: <Posts /> },
        { path: 'blog', element: <Blog /> },
        { path: 'referrals', element: <Referrals /> },
        { path: 'content', element: <Content /> },
        { path: 'banned-words', element: <BannedWords /> },
        { path: 'platform-fees', element: <PlatformFees /> },
        { path: 'platform-infos', element: <PlatformFeesInfos /> },
        { path: 'platform-users', element: <PlatformUsers /> },
        { path: 'mass-messages', element: <MassMessages /> },
        { path: 'medias', element: <Medias /> },
        { path: 'all-medias', element: <Medias /> },
        { path: 'reviews', element: <Reviews /> },
        { path: 'payouts', element: <Payouts /> },
        { path: 'transactions', element: <Spenders /> },
        // { path: 'transactions', element: <Transactions /> },
        { path: 'masspays', element: <Masspay /> },
        { path: 'masspay-exports', element: <MasspayExport /> }
      ]
    },
    {
      path: USER_ROUTES.INDEX,
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to={USER_ROUTES.LIST} replace /> },
        { path: USER_ROUTES.LIST_INDEX, element: <User /> },
        { path: USER_ROUTES.NEW_CREATORS_INDEX, element: <User /> },
        { path: USER_ROUTES.ALL_CREATORS_INDEX, element: <User /> },
        { path: USER_ROUTES.FAVOURITE_CREATORS_INDEX, element: <FavouriteUser /> },
        { path: USER_ROUTES.WITH_CARD_INDEX, element: <User /> },
        { path: USER_ROUTES.WITH_VALID_CARD_INDEX, element: <User /> },
        { path: USER_ROUTES.WITHOUT_VALID_CARD_INDEX, element: <User /> },
        { path: USER_ROUTES.WITH_BANNED_WORDS_INDEX, element: <UserBannedWords /> },
        { path: USER_ROUTES.ALL_INDEX, element: <User /> }
      ]
    },
    {
      path: POST_ROUTES.INDEX,
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to={POST_ROUTES.LIST} replace /> },
        { path: POST_ROUTES.LIST_INDEX, element: <POST /> },
        { path: POST_ROUTES.REPORTED_INDEX, element: <POST /> }
      ]
    },
    {
      path: SPENDERS_ROUTES.INDEX,
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to={SPENDERS_ROUTES.TOP} replace /> },
        { path: SPENDERS_ROUTES.TOP_INDEX, element: <Spenders /> },
        { path: SPENDERS_ROUTES.RECENT_INDEX, element: <Spenders /> },
        { path: SPENDERS_ROUTES.SUBSCRIBERS_INDEX, element: <Fans /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
