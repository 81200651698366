import { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import { fetchRecord } from '../services/Axios';
import { DASHBOARD } from '../constants/ApiEndPoint';

export default function DashboardApp() {
  const [newUsers, setNewUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalWeeklyPayments, setTotalWeeklyPayments] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);

  const listUsers = async () => {
    const response = await fetchRecord(DASHBOARD.STATS);
    if (response) {
      if (response.status === 200) {
        setNewUsers(response.data.newUsers);
        setTotalUsers(response.data.totalUsers);
        setTotalWeeklyPayments(response.data.totalWeeklyPayments);
        setTotalPayments(response.data.totalPayments);
      } else {
        setNewUsers(0);
        setTotalUsers(0);
        setTotalWeeklyPayments(0);
        setTotalPayments(0)
      }
    }
  };
  
  useEffect(() => {
    listUsers().then().catch().finally();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales TOTAL={newUsers}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers TOTAL={totalUsers}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders TOTAL={totalWeeklyPayments}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports TOTAL={totalPayments}/>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
