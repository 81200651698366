export const DASHBOARD = {
  STATS: 'dashboard/stats',
}

export const AUTHENTICATION = {
  VERIFY_USER: 'auth/verify-user',
  VERIFY_CODE: 'auth/verify-code',
  LOGIN: 'auth/login',
  LOGIN_BY_ID: 'auth/loginById',
  REGISTER: 'auth/register',
  USER_PROFILE: 'auth/isLoggedIn',
  EMAIL_VERIFY: 'auth/verify-email',
  LOGOUT: 'auth/logout',
  FORGOT_PASSWORD: 'auth/forgotPassword',
  RESET_PASSWORD: 'auth/resetPassword',
  VERIFY_RESET_PASSWORD_TOKEN: 'auth/verifyResetPasswordToken'
};

export const USER = {
  HOME: 'user/home',
  LIST: 'user/list',
  LIST_FAVOURITE: 'user/list-favourite',
  LIST_CREATORS: 'user/list-creators',
  VERIFY: 'user/verify',
  MARK_AS_FAVOURITE: 'user/markAsFavourite',
  VERIFY_EMAIL: 'user/verifyEmail',
  BLOCK: 'user/block',
  UNBLOCK: 'user/unblock',
  ADD_AS_CREATOR: 'user/addAsCreator',
  REMOVE_AS_CREATOR: 'user/removeAsCreator',
  LIST_PLATFORM_PAYMENTS: 'user/listPlatFormPayments',
  APPLICATION_CHARGE: 'user/applicationCharge',
  CHANGE_PASSWORD: 'user/changePassword',
  USERS_WITH_CARD: 'spenders/usersWithCard',
  USERS_WITHOUT_CARD: 'spenders/usersWithOutCard',
  SET_BANKING_DETAILS: 'user/setBankingDetails',
  GET_EARNING_STATS: 'user/getEarningStats'
};

export const POST = {
  LIST: 'post/list',
  BLOCK: 'post/block',
  UNBLOCK: 'post/unblock',
  DELETE_POST: 'post/deletePost',
  RESTORE: 'post/restore',
  MARK_REVIEWED: 'post/markReviewed',
};

export const REFERRALS = {
  LIST: 'referral/list',
  PAY: 'referral/pay'
};

export const SPENDERS = {
  TOP: 'spenders/list/top',
  RECENT: 'spenders/list/recent',
  ADD_SUBSCRIBERS: 'spenders/addSubscribers',
  LIST_SUBSCRIBERS: 'spenders/listSubscribers'
};

export const PAGE = {
  TNC: 'page/get/TermsAndCondition',
  PRIVACY_POLICY: 'page/get/PrivacyPolicy',
  SUPPORT: 'page/get/support',
  CREATOR_AGREEMENT: 'page/get/creatorAgreement',
  OUR_MISSION: 'page/get/ourMission',
  HELP_CENTRE: 'page/get/helpCentre',
  SAVE_CONTENT: 'page/edit',
  COMPLAINS_POLICY: 'page/get/ComplaintsPolicy',
  CONTENT_REMOVAL_PROCESS:'page/get/ContentRemovalProcess',
  CONTACT:'page/get/Contact',
};

export const BANNED_WORDS = {
  GET: 'banned-words/get',
  SAVE: 'banned-words/save',
  USERS: 'banned-words/users',
};

export const PLATFORM_FEES = {
  SET: 'platformFees/set',
  GET_LATEST_INFO: 'platformFees/getLatest',
  GET_PLATFORM_FEE_INFOS: 'platformFees/getInfos',
  GET_PLATFORM_USERS: 'platformFees/getPlatformUsers'
};

export const MASS_MESSAGE = {
  LIST: 'mass-messages/list',
  UNSEND: 'mass-messages/unSend'
};

export const MEDIA = {
  LIST: 'mediaFiles/list'
};

export const BANKING_REVIEW = {
  LIST: 'bankingReview/list',
  ADD_AS_CREATOR: 'bankingReview/addAsCreator',
  REMOVE_AS_CREATOR: 'bankingReview/removeAsCreator'
};

export const PAYOUT_REQUESTS = {
  LIST: 'payoutRequest/list',
  MARK_AS_PAID: 'payoutRequest/markAsPaid'
};

export const TRANSACTIONS = {
  LIST: 'transactions/list'
};

export const MASSPAY_REQUESTS = {
  LIST: 'masspay/list',
  EXPORT: 'masspay/export',
  MARK_AS_PAID: 'masspay/markAsPaid',
  MARK_AS_UNPAID: 'masspay/markAsUnpaid'
};