import {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/styles';
import {Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TimelineDot from '@material-ui/lab/TimelineDot';
import moment from 'moment';
import {fetchRecord} from '../../../services/Axios';
import {USER} from "../../../constants/ApiEndPoint";
import SimpleBackdrop from "../../common/SimpleBackdrop";

const formatDate = date => (
  moment(date).format('MMM D, YYYY')
)

export default function EarningStatics(props) {
  const {userId,setIsLoading} = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  const getAmount = (type) => (
     {
      gross: stats?.[type]?.gross ? stats?.[type]?.gross / 100 : 0,
      net: stats?.[type]?.net ? stats?.[type]?.net / 100 : 0
      }
  );

  const subscriptionsAmount = getAmount('SUBSCRIPTION');
  const tipsAmount = getAmount('TIP');
  const postsAmount = getAmount('POST_PURCHASE');
  const messagesAmount = getAmount('MESSAGE');
  const referralsAmount = getAmount('referral');
  const streamsAmount = getAmount('STREAMS');
  const personalMediaAmount = getAmount('PERSONAL_MEDIA');

  const getTotal = () => (
   {
      gross:
        subscriptionsAmount.gross +
        tipsAmount.gross +
        postsAmount.gross +
        messagesAmount.gross +
        referralsAmount.gross +
        streamsAmount.gross,
      net:
        subscriptionsAmount.net +
        tipsAmount.net +
        postsAmount.net +
        messagesAmount.net +
        referralsAmount.net +
        streamsAmount.net
    }
  );


  const getStats = async (err, data) => {
    setLoading(true);
    const formattedFrom = moment().toISOString();
    const formattedTo = moment().toISOString();

    const response = await fetchRecord(USER.GET_EARNING_STATS,userId);

    if (response && response.data && response.status === 200) {
      setStats(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStats().then().catch().finally();
  }, []);

  const getDecimal = number => ( number.toFixed(2))


  const content = (dotColor, itemName, gross, net) => (
    <Grid item container style={{marginTop: 10}}>
      <Grid item container>
        <Grid
          item
          container
          direction="row"
          justifyContent="left"
          alignItems="left"
          xs={6}
        >
          <TimelineDot style={{backgroundColor: dotColor}}/>
          <h5 className={classes.itemName}>{itemName}</h5>
        </Grid>
        <Grid item contianer xs={3} justify="center">
          <h5 className={classes.gross}>${getDecimal(gross)}</h5>
        </Grid>
        <Grid item contianer xs={3} justify="flex-end">
          <h5 className={classes.net}>${getDecimal(net)}</h5>
        </Grid>
      </Grid>
      <Grid item container>
        <Divider className={classes.divider}/>
      </Grid>
    </Grid>
  )

  const total = () => (
    <Grid item container style={{marginTop: 0}}>
      <Grid item container>
        <Divider className={classes.divider1}/>
      </Grid>
      <Grid item container style={{marginTop: 15}}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={6}
        >
          <h5 className={classes.total}>TOTAL</h5>
        </Grid>
        <Grid item contianer xs={3} justify="center">
          <h5 className={classes.totalGross}>
            <subtitle1 >
              GROSS
            </subtitle1>
            {' '}
            ${getDecimal(getTotal().gross)}
          </h5>
        </Grid>
        <Grid item contianer xs={3} justify="flex-end">
          <h5 className={classes.totalNet}>
            <subtitle1 >
              NET
            </subtitle1>
            {' '}
            ${getDecimal(getTotal().net)}
          </h5>
        </Grid>
      </Grid>
    </Grid>
  )

  const showPicker = () => {
    setOpen(true);
  };

  const handleDatePickerDialog = () => {
    setOpen(false);
  };

  return (
    <div>
      {loading && <SimpleBackdrop />}
      <Grid item container>
        {content(
          'blue',
          'SUBSCRIPTION',
          subscriptionsAmount.gross,
          subscriptionsAmount.net
        )}
        {content(
          'blue',
          'TIPS',
          tipsAmount.gross,
          tipsAmount.net
        )}
        {content('red', 'POST', postsAmount.gross, postsAmount.net)}
        {content(
          'orange',
          'MESSAGE',
          messagesAmount.gross,
          messagesAmount.net
        )}
        {content(
          'violet',
          'REFERALS',
          referralsAmount.gross,
          referralsAmount.net
        )}
        {content(
          'gray',
          'PERSONAL MEDIA',
          personalMediaAmount.gross,
          personalMediaAmount.net
        )}
        {content(
          'green',
          'STREAMS',
          streamsAmount.gross,
          streamsAmount.net
        )}
        {total()}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

  },

  topTitle: {
    fontWeight: 600,
    fontSize: 14,

    marginTop: 15,
    marginLeft: 20
  },

  dateContainer: {
    width: '100%',
    marginLeft: 20,
    marginRight: 20,
    padding: 15,
    marginTop: 15,
    borderRadius: 40,
    cursor: 'pointer'
  },

  dateText: {
    fontSize: 16,
    textAlign: 'center',
    flex: 1
  },

  date: {
    fontWeight: 600,
  },
  itemName: {
    marginLeft: 10,
    fontWeight: 500,
    marginTop: 6,
  },
  gross: {
    flex: 1,
    textAlign: 'center',
    marginTop: 5,
    fontWeight: 500,
    fontSize: 14,
  },
  net: {
    flex: 1,
    textAlign: 'right',
    marginTop: 5,
    fontWeight: 500,
    fontSize: 14,
  },
  divider: {
    width: '100%',
    height: 0.4,
    marginTop: 10
  },
  divider1: {
    width: '100%',
    height: 1,
    marginTop: 0
  },
  total: {
    marginLeft: 6,
    fontWeight: 500,
    fontSize: 14
  },
  totalGross: {
    flex: 1,
    textAlign: 'center',
    marginTop: 3,
    fontWeight: 600,
    fontSize: 14,
  },
  totalNet: {
    flex: 1,
    textAlign: 'right',
    marginTop: 3,
    fontWeight: 600,
    fontSize: 14,
  },
  heading: {
    fontWeight: 500,
  },
  button: {
    fontSize: 13,
    textTransform: 'none',
    marginLeft: 15,
    borderRadius: 20,
    marginBottom: 10,
    textDecoration: 'none',
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 35,
    maxHeight: 35,
  },

}));

