import { styled } from '@material-ui/core/styles';
import { Card, Container } from '@material-ui/core';
import Page from '../components/Page';
import { VerifyForm } from '../components/authentication/login';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 452,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '90vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyOtp() {
  return (
    <RootStyle title="Verify OTP">
      <Container maxWidth="sm">
        <ContentStyle>
          <SectionStyle>
            <img
              src="/static/illustrations/illustration_login.png"
              alt="login"
              style={{ padding: 12 }}
            />
          </SectionStyle>
          <VerifyForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
