import Axios from 'axios';
import Configs from '../configs/Configs';
import { GENERAL } from '../constants/ApiResponseFormat';
import { getAccessToken, getFirebaseToken } from './Storage';

const API = Axios.create({
  baseURL: Configs.API_URI,
  timeout: 90000,
  headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache, no-store' }
});

API.interceptors.request.use(
  config => {
    config.headers.Authorization = getAccessToken();
    return config;
  },
  e => Promise.reject(e)
);

const exceptionHandler = e => {
  let responseContent = {};
  if (e.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    responseContent = {
      status: e.response.status,
      message: e.response.data.message,
      data: e.response.data.data
    };
    // return responseContent;
    // console.log(e.response.status);
    // console.log(e.response.headers);
  } else if (e.request) {
    // The request was made but no response was received
    // `e.request` is an instance of XMLHttpRequest in the
    // browser and an instance of
    // http.ClientRequest in node.js
    responseContent = {
      status: 503,
      message: 'Service Unavailable',
      data: ''
    };
  } else {
    responseContent = {
      status: 500,
      message: e.message,
      data: ''
    };
  }
  return responseContent;
};

export const fetchRecord = async (relativeUri, pathParam1 = '', pathParam2 = '', queryParams) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  try {
    if (pathParam1 !== '') {
      relativeUri = `${relativeUri}/${pathParam1}`;
    }
    if (pathParam2 !== '') {
      relativeUri = `${relativeUri}/${pathParam2}`;
    }
    if (queryParams) {
      relativeUri = `${relativeUri}?${queryParams}`;
    }
    const response = await API.get(relativeUri)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.data
        };

        return responseContent;
      })
      .catch(e => {
        responseContent = exceptionHandler(e);
      });

    return responseContent;
  } catch (e) {
    return GENERAL;
  }
};

export const postRecord = async (relativeUri, data,pathParam1 = '', pathParam2 = '',) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  if (pathParam1 !== '') {
    relativeUri = `${relativeUri}/${pathParam1}`;
  }
  if (pathParam2 !== '') {
    relativeUri = `${relativeUri}/${pathParam2}`;
  }

  try {
    const response = await API.post(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.data
        };
        return responseContent;
      })
      .catch(e => {
        responseContent = exceptionHandler(e);
      });
    return responseContent;
  } catch (e) {
    return exceptionHandler(e);
  }
};

export const patchRecord = async (relativeUri, data, pathParam1 = '', pathParam2 = '', queryParams) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  if (pathParam1 !== '') {
    relativeUri = `${relativeUri}/${pathParam1}`;
  }
  if (pathParam2 !== '') {
    relativeUri = `${relativeUri}/${pathParam2}`;
  }
  if (queryParams) {
    relativeUri = `${relativeUri}?${queryParams}`;
  }

  try {
    const response = await API.patch(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.data
        };

        return responseContent;
      })
      .catch(e => {
        responseContent = exceptionHandler(e);
      });
    return responseContent;
  } catch (e) {
    return exceptionHandler(e);
  }
};

export const putRecord = async (relativeUri, pathParam1 = '', pathParam2 = '', data) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };

  try {
    if (pathParam1 !== '') {
      relativeUri = `${relativeUri}/${pathParam1}`;
    }
    if (pathParam2 !== '') {
      relativeUri = `${relativeUri}/${pathParam2}`;
    }
    const response = await API.put(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.data
        };

        return responseContent;
      })
      .catch(e => {
        responseContent = exceptionHandler(e);
      });
    return responseContent;
  } catch (e) {
    return exceptionHandler(e);
  }
};

export const deleteRecord = async (relativeUri, data, pathParam1 = '', pathParam2 = '') => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  if (pathParam1 !== '') {
    relativeUri = `${relativeUri}/${pathParam1}`;
  }
  if (pathParam2 !== '') {
    relativeUri = `${relativeUri}/${pathParam2}`;
  }

  try {
    const response = await API.delete(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.data
        };

        return responseContent;
      })
      .catch(e => {
        responseContent = exceptionHandler(e);
      });
    return responseContent;
  } catch (e) {
    return exceptionHandler(e);
  }
};


export const download = async (uri, data) => {
  try {
    const API = Axios.create({
      baseURL: Configs.API_URI,
      timeout: 300000,
      headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache, no-store' }
    });

    API.interceptors.request.use(
      config => {
        config.headers.Authorization = getAccessToken();
        config.headers.Firebase = getFirebaseToken();
        return config;
      },
      e => Promise.reject(e)
    );

    const response1 = await API.post(uri, data, { responseType: 'blob' })
      .then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), data.fileName);
          console.log('apple');
        } else {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', data.fileName);
          document.body.appendChild(fileLink);

          fileLink.click();

          GENERAL.status = 200;
          GENERAL.message = 'File downloaded successfully.';
          return GENERAL;
        }
      })
      .catch(e => {
        const exception = exceptionHandler(e);
        GENERAL.status = 500;
        GENERAL.message = e.message;

        return GENERAL;
      });
    return GENERAL;
  } catch (e) {
    return false;
  }
};

