import { CLEAR_NOTIFY, SET_NOTIFY } from '../../actions/Notify';
import { GENERAL } from '../../../constants/ApiResponseFormat';

const _body = GENERAL;
_body.message = 'Something went wrong.';
export default (state = null, { body, type }) => {
  Object.freeze(state);
  switch (type) {
    case SET_NOTIFY:
      return body ||_body;
    case CLEAR_NOTIFY:
      return null;
    default:
      return state;
  }
};
