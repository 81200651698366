import {Icon} from '@iconify/react';
import React, {useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import moment from "moment";
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-circle-2-fill';
import AttachFileIcon from "@material-ui/icons/AttachFile";

// material
import {Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import {fetchRecord} from '../../../services/Axios';
import {PAYOUT_REQUESTS} from '../../../constants/ApiEndPoint';
import {
  DATE_FORMAT,
  PAYOUT_STATUS_PAID,
  PAYOUT_STATUS_REQUESTED,
  POST_ACTIVE
} from '../../../constants';
import {setModal} from "../../../stores/actions/Modal";
import PlayMedia from "../../PlayMedia";
import Label from "../../Label";


// ----------------------------------------------------------------------

export default function PayoutMoreMenu({requestId, setIsLoading, update, setUpdate, status, bankingDetails}) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const markAsPaid = async (markAs = true) => {
    if (markAs) {
      setIsLoading(true);
      setIsOpen(false);
      const response = await fetchRecord(PAYOUT_REQUESTS.MARK_AS_PAID, requestId);
      if (response) {
        setUpdate(!update);
        setIsLoading(false);
      }
    }

  };

  // const bankInfo = (bankingDetails) => {
  function bankInfo(bankingDetails) {
    if(!bankingDetails){
      return (
        <>
          User has not provided bank details.
        </>
      )
    }
    return (
        <table>
          <tr>
            <td>&nbsp;</td>
            <td>BANK DETAILS</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            &nbsp;
          </tr>
          <tr>
            <td>Full name: </td>
            <td> {bankingDetails.fullName}</td>
          </tr>
          <tr>
            <td>Address: </td>
            <td> {bankingDetails.address}</td>
          </tr>
          <tr>
            <td>Bank: </td>
            <td> {bankingDetails.bankDetails}</td>
          </tr>
          <tr>
            <td>Dob: </td>
            <td>
              <Label variant="ghost" color="info">
                {moment(bankingDetails.dateOfBirth).format(DATE_FORMAT)}
              </Label>
            </td>
          </tr>
          <tr>
            <td>ID: </td>
            <td>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => handleMedia(bankingDetails.idUrl)}
                endIcon={<AttachFileIcon />}
              >
              View
              </Button>
            </td>
          </tr>

        </table>
    );
  }

  const viewBank = () => {
    setIsOpen(false);
    dispatch(setModal({status: 401, message: bankInfo(bankingDetails)}));
  };

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  const handleMedia = (link) => {

    setMedia({
      type:isImage(link) ? 'PHOTO':'IFRAME',
      link
    });
    setOpen(true);
  };

  function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20}/>
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {width: 200, maxWidth: '100%'}
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >

        <MenuItem sx={{color: 'text.secondary'}}
                  onClick={() => status === PAYOUT_STATUS_REQUESTED ? markAsPaid(true) : markAsPaid(false)}>
          <ListItemIcon>
            <Icon icon={status === POST_ACTIVE ? checkmarkFill : checkmarkFill} width={24} height={24}
                  color={status === POST_ACTIVE ? "#B72136 " : "#229a16"}/>
          </ListItemIcon>
          <ListItemText primary={status === PAYOUT_STATUS_PAID ? "Paid" : "Mark As Paid"}
                        primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        <MenuItem sx={{color: 'text.secondary'}} onClick={() => viewBank(bankingDetails)}>
          <ListItemIcon>
            <Icon icon={briefcaseFill} width={24} height={24} color="#B72136"/>
          </ListItemIcon>
          <ListItemText primary="View Bank Details" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      </Menu>

      {open &&
        <PlayMedia
          media={media}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
        />
      }
    </>
  );
}
