import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

import PostSort from './PostSort';
import { fetchRecord } from '../../../services/Axios';
import { USER } from '../../../constants/ApiEndPoint';
import { prepareQueryParams } from '../../../utils/General';



// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

PostListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};



export default function PostListToolbar({
                                          numSelected,
                                          filterName,
                                          onFilterName,
                                          filterOptions,
                                          filter,
                                          setFilter,
                                          newCreatorsPage,
                                          creator,
                                          setCreator
}) {
  const [creators, setCreators] = useState([]);
  const [creatorsLoader, setCreatorsLoader] = useState(false);

  const onInputChange = async (event, value) => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      creators: true,
      filter
    };
    if (event && (event.type === 'click' || event.key === 'Enter')) {
      value = '';
    }
    queryParams.searchText = value;
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response.status === 200) {
      setCreators(response.data.docs);
    }
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search post..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Autocomplete
            style={{width:240}}
            id="creator"
            name="creator"
            value={creator}
            onInputChange={onInputChange}
            onChange={(event,value) => setCreator(value)}
            options={creators}
            getOptionLabel={option =>
              option.displayName ? option.displayName : option
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label="Creator"
                placeholder="Creator"
                InputLabelProps={{
                  shrink: true
                }}
                value={creator && creator.displayName}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {creatorsLoader ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </>


      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        !newCreatorsPage && <PostSort options={filterOptions} filter={filter} setFilter={setFilter}/>
      /*  <Tooltip title="Filter list">
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip> */
      )}
    </RootStyle>
  );
}
