import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import { IconButton, InputAdornment, Stack,Typography } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import SimpleBackdrop from '../../common/SimpleBackdrop';

import ClearNotify from '../../common/ClearNotify';
import Notify from '../../common/Notify';
import { login } from '../../../stores/actions/Authorize';
import { DASHBOARD_ROUTES } from '../../../constants/SiteRoutes';
import { fetchRecord, postRecord } from '../../../services/Axios';
import { USER } from '../../../constants/ApiEndPoint';
import { setFlash } from '../../../stores/actions/Flash';




const useStyles = makeStyles(theme => ({
  mediaWrapper: {
    margin: 10
  }

}));
export default function ChangePassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, setOpen,userId,setUpdate,update,setIsLoading,user} = props;
  const [password, setPassword] = useState('');
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSubmit = async() => {
    const body = {password};
    setIsLoading(true);
    setOpen(false);
    const response = await postRecord( USER.CHANGE_PASSWORD,body,userId);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
      dispatch(setFlash(response));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // custom validator
    ValidatorForm.addValidationRule('isPasswordMatch', value => (value === password)

    );
  });

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Password</DialogTitle>
        <DialogContent className={classes.mediaWrapper}>
          <div>
            <Typography variant="body2">Changing password for <Typography variant="body2" color="green" style={{display:'inline'}}>{user.displayName} ({user.username})</Typography></Typography>
          </div>
          <ValidatorForm onSubmit={handleSubmit}>
            <ClearNotify/>
            <Notify/>
            <TextValidator
              label="Password"
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter Password"
              helperText=""
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              value={password}
              validators={['required','minStringLength:6','maxStringLength:26']}
              errorMessages={['Password is required.','Password must be at least 6 characters long.','Password must be less than 26 characters.']}
              // variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </ValidatorForm>

        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
