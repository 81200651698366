import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@material-ui/core';

// ----------------------------------------------------------------------

ReferralSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func
};

export default function ReferralSort({ options,setFilter,filter }) {
  const onChange = (e)=>{
    setFilter(e.target.value);
  }
  return (
    <TextField select size="small" value={filter} onChange={onChange}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
