import { useLocation } from 'react-router';
import moment from 'moment';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import eyeOutline from "@iconify/icons-eva/arrow-ios-forward-outline";
// material
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CardHeader, IconButton,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { fetchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import {BANKING_REVIEW, USER} from '../constants/ApiEndPoint';
import {DATE_FORMAT, USER_ACTIVE} from '../constants';
import { prepareQueryParams } from '../utils/General';
import MyFrame from "../components/MyFrame";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Reviews() {
  const location = useLocation();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter, setFilter] = useState('inReview');
  const [update, setUpdate] = useState(false);

  const TABLE_HEAD = [
    { id: 'displayName', label: 'User', alignRight: false },
    { id: 'fullName', label: 'Full Name', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'dateOfBirth', label: 'DOB', alignRight: false },
    { id: 'bankDetails', label: 'Bank', alignRight: false },
    { id: 'idUrl', label: 'ID', alignRight: false },
    { id: 'accountName', label: 'Account Name', alignRight: false },
    { id: 'accountNumber', label: 'Account Number', alignRight: false },
    { id: 'bankName', label: 'Bank Name', alignRight: false },
    { id: 'bsb', label: 'Bsb', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'createdAt', label: 'Requested At', alignRight: false },
  ];


  TABLE_HEAD.push({ id: '' });

  // ----------------------------------------------------------------------

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(eRows);
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, 'newPage');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };


  const listRows = async () => {
    const queryParams = {
      page: page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(BANKING_REVIEW.LIST, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setRows(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setRows([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listRows().then().catch().finally();
  }, [page, filter, update]);

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'inReview', label: 'In Review' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'declined', label: 'Declined' },

  ];

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  const handleMedia = (link) => {

    setMedia({
      type:'IFRAME',
      link
    });
    setOpen(true);
  };

  return (
    <Page title="Reviews">
      <Container>
        {loading && <SimpleBackdrop />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banking Reviews
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterOptions={filterOptions}
            filter={filter}
            setFilter={setFilter}
            isReviewPage
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {rows.map((row) => {
                    const {
                      _id,
                      fullName,
                      address,
                      dateOfBirth,
                      bankDetails,
                      idUrl,
                      isVerifiedByAdmin,
                      applicationCharge,
                      createdAt,
                      userInfo,
                      status,
                      accountName,
                      accountNumber,
                      bankName,
                      bsb
                    } = row;
                    const {
                      displayName,
                      username,
                      email,
                      company,
                      photoUri,
                      isCreator
                    } = userInfo;
                    const isItemSelected = selected.indexOf(username) !== -1;


                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="normal">
                        <CardHeader
                              style={{padding:0}}
                              avatar={<Avatar alt={displayName} src={photoUri} />}
                              title={
                                <Typography variant="subtitle2" noWrap>
                                  {displayName}
                                  &nbsp;
                                  <Label variant="ghost" color={isCreator ? 'primary' : 'secondary'}>
                                    {isCreator ? 'Creator' : 'User'}
                                  </Label>
                                </Typography>
                              }
                              // subheader={username}
                              subheader={
                                <>
                                  <Typography variant="subtitle2" noWrap style={{maxWidth: '200px'}}>
                                    {username}
                                  </Typography>

                                  <Typography variant="caption" noWrap>
                                    {email}
                                  </Typography>
                                  <Tooltip title={email}>
                                    <IconButton size="small">
                                      <Icon icon={eyeOutline} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                              
                            />
                        </TableCell>
                        <TableCell align="left">
                            {fullName}
                        </TableCell>
                        <TableCell align="left">
                            {address}
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color="info">
                            {moment(dateOfBirth).format(DATE_FORMAT)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {bankDetails}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => handleMedia(idUrl)}
                            endIcon={<AttachFileIcon />}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          {accountName}
                        </TableCell>
                        <TableCell align="left">
                          {accountNumber}
                        </TableCell>
                        <TableCell align="left">
                          {bankName}
                        </TableCell>
                        <TableCell align="left">
                          {bsb}
                        </TableCell>
                        <TableCell align="left">
                          {status}
                        </TableCell>

                        <TableCell align="left">
                            <Label variant="ghost" color="info">
                              {moment(createdAt).format(DATE_FORMAT)}
                            </Label>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            user={row}
                            applicationCharge={applicationCharge}
                            userId={_id}
                            setIsLoading={setIsLoading}
                            isVerifiedByAdmin={isVerifiedByAdmin}
                            update={update}
                            status={status}
                            setUpdate={setUpdate}
                            isCreator={isCreator}
                            isReviewPage
                            requestId={_id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {rows.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {open &&
        <MyFrame
          media={media}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
        />
      }
    </Page>
  );
}
