import S3FileUpload,{uploadFile,deleteFile} from "react-s3";
import ReactAwsS3 from "react-aws-s3/dist/react-aws-s3";
import uuid from 'uuid/dist/v1';
import Configs from "../configs/Configs";

const config = {
  bucketName: Configs.AWS.BUCKET,
  dirName: 'users',
  region: Configs.AWS.REGION,
  accessKeyId: Configs.AWS.ACCESS_KEY_ID,
  secretAccessKey: Configs.AWS.SECRET_ACCESS_KEY
};



const uploadToS3 = async (file,dirName='users') => {
  config.dirName = dirName;
/*  const s3Response = await uploadFile(file, config).then()
    .catch(); */
  const s3 = new ReactAwsS3(config);
  const key = `${uuid()}-${Date.now()}`;

  const s3Response = await s3.uploadFile(file, key); console.log(s3Response);

  return s3Response;

}

const deleteFromS3 = async (file,dirName='users') => {

  config.dirName = dirName;
  const s3 = new ReactAwsS3(config);
  deleteFile(file, config)
    .then(response => console.log(response))
    .catch(err => console.error(err))

}

export {
   uploadToS3,
   deleteFromS3
}
