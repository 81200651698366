import { useLocation } from 'react-router';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import USERLIST from '../_mocks_/user';
import { fetchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { SPENDERS, USER } from '../constants/ApiEndPoint';
import { USER_ACTIVE } from '../constants';
import { USER_ROUTES } from '../constants/SiteRoutes';
import { prepareQueryParams } from '../utils/General';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'displayName', label: 'Display Name', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Fans() {
  const location  = useLocation();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading,setIsLoading] = useState(false);
  const [users,setUsers] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter,setFilter] = useState('all');
  const [update,setUpdate] = useState(false);
  const [newCreatorsPage,setNewCreatorsPage] = useState( pathName === USER_ROUTES.NEW_CREATORS);

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
    setEmptyRows(eRows);
  }, [page,rowsPerPage]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => { console.log(newPage,'newPage')
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };

  useEffect(() => {

    setNewCreatorsPage( pathName === USER_ROUTES.NEW_CREATORS);
    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const listUsers = async () => {
    const queryParams = {
      page : page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      newCreatorsPage,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(SPENDERS.LIST_SUBSCRIBERS,'','',prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setUsers(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setUsers([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listUsers().then().catch().finally();
  }, [
    page,filter,update
  ]);


  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'creators', label: 'Creators' },
    /* { value: 'viewers', label: 'Viewers' }, */
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'In-Active' },
    { value: 'verified', label: 'Verified' },
    { value: 'unverified', label: 'Un-Verified' },
  ];

  return (
    <Page title="Users">
      <Container>
        {loading && <SimpleBackdrop />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>

        </Stack>

        <Card>
        { /*  <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterOptions={filterOptions}
            filter={filter}
            setFilter={setFilter}
            newCreatorsPage={newCreatorsPage}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, displayName,username,email, status, company, photoUri, isVerifiedByAdmin } = row;
                    const isItemSelected = selected.indexOf(username) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, username)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={displayName} src={photoUri} />
                            <Typography variant="subtitle2" noWrap>
                              {displayName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{username}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={isVerifiedByAdmin ? 'success' : 'error'}
                          >
                            {isVerifiedByAdmin && 'Verified'}
                            {!isVerifiedByAdmin && 'Not Verified'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status === USER_ACTIVE ? 'success' :'error'}
                          >
                            {status === 0 && 'Un-Verified email'}
                            {status === 1 && 'Active'}
                            {status === 2 && 'Admin Deleted'}
                            {status === 3 && 'User Deleted'}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            userId={_id}
                            setIsLoading={setIsLoading}
                            isVerifiedByAdmin={isVerifiedByAdmin}
                            update={update}
                            status={status}
                            setUpdate={setUpdate}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {users.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
