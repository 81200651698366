import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Toolbar, TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as ExcelJS from 'exceljs';
import MasspaySort from './MasspaySort';
import { fetchRecord } from '../../../services/Axios';
import { USER, MASSPAY_REQUESTS } from '../../../constants/ApiEndPoint';
import { prepareQueryParams } from '../../../utils/General';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  padding: theme.spacing(0, 1, 0, 3)
}));

// ----------------------------------------------------------------------

MasspayListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};



export default function MasspayListToolbar({
                                          numSelected,
                                          filterName,
                                          onFilterName,
                                          filterOptions,
                                          filter,
                                          setFilter,
                                          setIsLoading,
                                          newCreatorsPage,
                                          creator,
                                          setCreator
}) {
  const [creators, setCreators] = useState([]);
  const [creatorsLoader, setCreatorsLoader] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'internal user id', key: 'internal_user_id', width: 30 },
      { header: 'address1', key: 'address1', width: 10 },
      { header: 'address2', key: 'address2', width: 10 },
      { header: 'city', key: 'city', width: 10 },
      { header: 'state province', key: 'state_province', width: 20 },
      { header: 'postal code', key: 'postal_code', width: 20 },
      { header: 'country code (list: https://unstats.un.org/unsd/tradekb/knowledgebase/country-code)', key: 'country_code', width: 60 },
      { header: 'first name (if business, representative first name)', key: 'first_name', width: 30 },
      { header: 'middle name (if business, representative middle name)', key: 'middle_name', width: 30 },
      { header: 'last name (if business, representative last name)', key: 'last_name', width: 30 },
      { header: 'business name (only applicable when business)', key: 'business_name', width: 30 },
      { header: 'email', key: 'email', width: 30 },
      { header: 'mobile number', key: 'mobile_number', width: 30 },
      { header: 'language', key: 'language', width: 30 },
      { header: 'amount', key: 'amount', width: 30 },
      { header: 'source currency', key: 'source_currency', width: 30 },
      { header: 'destination currency (applicable to payouts only)', key: 'destination_currency', width: 30 },
      { header: 'notes', key: 'notes', width: 30 },
      { header: 'load id/payout id', key: 'load_id', width: 45 },
      { header: 'send notification', key: 'send_notification', width: 30 },
      { header: 'payout attributes (only required for payouts)', key: 'payout_attributes', width: 60 },
      { header: 'v1.1', key: 'version', width: 30 },
    ];
    const response = await fetchRecord(MASSPAY_REQUESTS.EXPORT);
    if (response) {
      if (response.status === 200) {
        const date = new Date();
        const month = (`0${date.getMonth()+1}`).slice(-2);

        response.data.docs.forEach((row) => {
          const displayName = row.userInfo.displayName.split(' ');
          const [first, second] = displayName;
          let firstName = '';
          let middleName = '';
          let lastName = '';
          if (displayName.length === 1) {
            firstName = first;
          } else if (displayName.length === 2) {
            firstName = first;
            lastName = second;
          } else {
            const [split1, split2] = row.userInfo.displayName.split(`${first} ${second} `);
            firstName = first;
            middleName = second;
            lastName = split2;
          }
          const randomString = Math.random().toString(36).slice(2, 10);
          worksheet.addRow({
            internal_user_id: row.user,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email: row.userInfo.email,
            language: 'en',
            amount: row.amount/100,
            source_currency: 'USD',
            destination_currency: 'USD',
            notes: `Commission for ${month}-${date.getDate()}-${date.getFullYear()}`,
            load_id: `${firstName}_Masspay_${month}_${date.getDate()}_${date.getFullYear()}_${randomString}`,
            send_notification: 'Y',
          });
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${month}_${date.getDate()}_${date.getFullYear()}.xlsx`;
          a.click();
        });
      }
      setIsLoading(false);
    }
  };

  const onInputChange = async (event, value) => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      creators: true,
      filter
    };
    if (event && (event.type === 'click' || event.key === 'Enter')) {
      value = '';
    }
    queryParams.searchText = value;
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response.status === 200) {
      setCreators(response.data.docs);
    }
  };
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <>
        <Autocomplete
          style={{width:240, marginRight: 10}}
          id="creator"
          name="creator"
          value={creator}
          onInputChange={onInputChange}
          onChange={(event,value) => setCreator(value)}
          options={creators}
          getOptionLabel={option =>
            option.displayName ? option.displayName : option
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              label="Creator"
              placeholder="Creator"
              InputLabelProps={{
                shrink: true
              }}
              value={creator && creator.displayName}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {creatorsLoader ? <CircularProgress color="inherit" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        
        <MasspaySort options={filterOptions} filter={filter} setFilter={setFilter} />

        <Button variant="outlined" onClick={handleExport} style={{padding: "16px", marginLeft: "10px"}}>
          Export CSV
        </Button>
      </>
    </RootStyle>
  );
}
