import moment from 'moment';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import eyeOutline from '@iconify/icons-eva/arrow-ios-forward-outline';
import {
  Avatar,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  CardHeader,
  IconButton
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../components/_dashboard/user';
import { fetchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { BANNED_WORDS } from '../constants/ApiEndPoint';
import { prepareQueryParams } from '../utils/General';
import {DATE_FORMAT} from '../constants';

export default function UserBannedWords() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const TABLE_HEAD = [
    { id: 'displayName', label: 'User', alignRight: false },
    { id: 'content', label: 'Content', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'sentTo', label: 'Sent To', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false }
  ];

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
    setEmptyRows(eRows);
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const listUsers = async () => {
    const queryParams = {
      page: page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order
    };
    setIsLoading(true);
    const response = await fetchRecord(BANNED_WORDS.USERS, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setUsers(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setUsers([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listUsers().then().catch().finally();
  }, [page]);

  return (
    <Page title="Users">
      <Container>
        {loading && <SimpleBackdrop />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Banned Words
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, userInfo, sendToInfo, content, type, createdAt } = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell component="th" scope="row" padding="normal">
                          {userInfo && userInfo.length > 0 && (
                            <CardHeader
                              style={{ padding: 0 }}
                              avatar={<Avatar alt={userInfo[0].displayName} src={userInfo[0].photoUri} />}
                              title={
                                <Typography variant="subtitle2" noWrap>
                                  {userInfo[0].displayName}
                                  &nbsp;
                                  <Label variant="ghost" color={userInfo[0].isCreator ? 'primary' : 'secondary'}>
                                    {userInfo[0].isCreator ? 'Creator' : 'User'}
                                  </Label>
                                </Typography>
                              }
                              subheader={
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    style={{ maxWidth: '200px' }}
                                  >
                                    {userInfo[0].username}
                                  </Typography>

                                  <Typography variant="caption" noWrap>
                                    {userInfo[0].email}
                                  </Typography>
                                  <Tooltip title={userInfo[0].email}>
                                    <IconButton size="small">
                                      <Icon icon={eyeOutline} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost">{content}</Label>
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost">{type.toUpperCase()}</Label>
                        </TableCell>
                        <TableCell align="left">
                          {sendToInfo && sendToInfo.length > 0 && (
                            <CardHeader
                              style={{ padding: 0 }}
                              avatar={<Avatar alt={sendToInfo[0].displayName} src={sendToInfo[0].photoUri} />}
                              title={
                                <Typography variant="subtitle2" noWrap>
                                  {sendToInfo[0].displayName}
                                  &nbsp;
                                  <Label variant="ghost" color={sendToInfo[0].isCreator ? 'primary' : 'secondary'}>
                                    {sendToInfo[0].isCreator ? 'Creator' : 'User'}
                                  </Label>
                                </Typography>
                              }
                              subheader={
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    style={{ maxWidth: '200px' }}
                                  >
                                    {sendToInfo[0].username}
                                  </Typography>

                                  <Typography variant="caption" noWrap>
                                    {sendToInfo[0].email}
                                  </Typography>
                                  <Tooltip title={sendToInfo[0].email}>
                                    <IconButton size="small">
                                      <Icon icon={eyeOutline} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost">{moment(createdAt).format(DATE_FORMAT)}</Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {users.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery="" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
