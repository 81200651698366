const accessTokenIdentifierKey = 'adminAccessToken';
const fcmIdentifierKey = 'fcmToken';

export const setAccessToken = (token) => {

   localStorage.setItem(accessTokenIdentifierKey, token)
};

export const getAccessToken = () => {
   const key = localStorage.getItem(accessTokenIdentifierKey);
   return key;
};

export const clearAccessToken = () => {
   localStorage.removeItem(accessTokenIdentifierKey);
};

export const getFirebaseToken = () => {
   localStorage.getItem(fcmIdentifierKey);
};


