import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import {Avatar, IconButton, InputAdornment, Stack, TextField, Typography} from '@material-ui/core';
import {CardMedia, MenuItem} from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from "moment";
import SimpleBackdrop from '../../common/SimpleBackdrop';

import ClearNotify from '../../common/ClearNotify';
import Notify from '../../common/Notify';
import { login } from '../../../stores/actions/Authorize';
import { DASHBOARD_ROUTES } from '../../../constants/SiteRoutes';
import { fetchRecord, postRecord } from '../../../services/Axios';
import { USER } from '../../../constants/ApiEndPoint';
import { setFlash } from '../../../stores/actions/Flash';
import {uploadToS3} from "../../../services/AwsService";
import {MAX_IMAGE_UPLOAD_SIZE} from "../../../constants";






const useStyles = makeStyles(theme => ({
  mediaWrapper: {
    margin: 10
  }

}));
export default function BankingDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, setOpen,userId,setUpdate,update,setIsLoading,user} = props;
  const theme = useTheme();
  const [fullName, setFullName] = useState(user?.bankingDetails?.fullName || '');
  const [address, setAddress] = useState(user?.bankingDetails?.address);
  const [dateOfBirth, setDateOfBirth] = useState(moment(user?.bankingDetails?.dateOfBirth));
  const [bankDetails, setBankDetails] = useState(user?.bankingDetails?.bankDetails);
  const [idUrl, setIdUrl] = useState(user?.bankingDetails?.idUrl);
  const [file,setFile] = useState('');
  const [bankName, setBankName] = useState(user?.bankingDetails?.bankName);
  const [accountNumber, setAccountNumber] = useState(user?.bankingDetails?.accountNumber);
  const [accountName, setAccountName] = useState(user?.bankingDetails?.accountName);
  const [bsb, setBsb] = useState(user?.bankingDetails?.bsb);

  const handleSubmit = async() => {
    const body = {
      fullName,
      address,
      dateOfBirth,
      bankDetails,
      bankName,
      accountNumber,
      accountName,
      bsb
    };
    if (file) {
      const s3Response = await uploadToS3(file);
      body.idUrl = s3Response.location;
    }
    setIsLoading(true);
    setOpen(false);
    const response = await postRecord(USER.SET_BANKING_DETAILS,body,userId);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
      dispatch(setFlash(response));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isFileImage = file => (file && file.type.split('/')[0] === 'image');

  const maxFileSize = file => (file && file.size <= 100 * 1000 * 1024);

  const uploadImageNew = e => {
    e.stopPropagation();
    const file = e.target.files[0];
    if (file !== undefined) {
      const imagePath = URL.createObjectURL(file);
      setIdUrl(imagePath);
      setFile(file);
    }
  };

  useEffect(() => {
    // custom validator
    ValidatorForm.addValidationRule('isImage', value => (!!isFileImage(file)));

    ValidatorForm.addValidationRule('maxFileSize', value => (!!maxFileSize(file)));
  });

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Banking Details</DialogTitle>
        <ValidatorForm onSubmit={handleSubmit}>
        <DialogContent className={classes.mediaWrapper}>
          <div>
            <Typography variant="body2">Banking Details for <Typography variant="body2" color="green" style={{display:'inline'}}>{user.displayName} ({user.username})</Typography></Typography>
          </div>

            <ClearNotify/>
            <Notify/>
            <TextValidator
              fullWidth
              label="Full Name"
              id="fullName"
              variant="outlined"
              value={fullName}
              margin="normal"
              onChange={e => setFullName(e.target.value)}
              validators={['required']}
              errorMessages={['Full Name is required.']}
            />
            <TextValidator
              fullWidth
              label="Address"
              id="address"
              variant="outlined"
              value={address}
              margin="normal"
              onChange={e => setAddress(e.target.value)}
              validators={['required']}
              errorMessages={['Address is required.']}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // views={["day","month","year"]}
                label="Date of Birth"
                value={dateOfBirth}
                // maxDate={moment().subtract(18, 'years')}
                onChange={setDateOfBirth}
                renderInput={(props) => <TextField margin="normal" {...props} />}
              />
            </LocalizationProvider>

            <TextValidator
              fullWidth
              multiline
              rows={3}
              label="Bank Details"
              id="bankDetails"
              variant="outlined"
              value={bankDetails}
              margin="normal"
              onChange={e => setBankDetails(e.target.value)}
              validators={['required']}
              errorMessages={['Bank Details is required.']}
            />

            <TextValidator
              fullWidth
              label="Bank Name"
              id="bankName"
              variant="outlined"
              value={bankName}
              margin="normal"
              onChange={e => setBankName(e.target.value)}
              validators={['required']}
              errorMessages={['Bank Name is required.']}
            />

          <TextValidator
            fullWidth
            label="Account Name"
            id="accountName"
            variant="outlined"
            value={accountName}
            margin="normal"
            onChange={e => setAccountName(e.target.value)}
            validators={['required']}
            errorMessages={['Account is required.']}
          />

          <TextValidator
            fullWidth
            label="Account Number"
            id="accountNumber"
            variant="outlined"
            value={accountNumber}
            margin="normal"
            onChange={e => setAccountNumber(e.target.value)}
            validators={['required']}
            errorMessages={['Account Number is required.']}
          />

          <TextValidator
            fullWidth
            label="BSB or other numbers"
            id="bsb"
            variant="outlined"
            value={bsb}
            margin="normal"
            onChange={e => setBsb(e.target.value)}
            validators={['required']}
            errorMessages={['Bsb is required.']}
          />

            <TextValidator
              fullWidth
              label="Upload ID"
              id="idUrl"
              variant="outlined"
              type="file"
              margin="normal"
              onChange={e => uploadImageNew(e)}
              validators={['isImage', 'maxFileSize']}
              errorMessages={[
                'Select image with jpg/jgep/png/gif format.',
                'File size must not be greater than 100mb'
              ]}
              InputLabelProps={{
                shrink: true
              }}
            />

            <CardMedia component="img" image={idUrl} />



          </DialogContent>
          <DialogActions>
            <Button  onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
