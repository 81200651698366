import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkMarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
// material
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { fetchRecord } from '../../../services/Axios';
import { REFERRALS } from '../../../constants/ApiEndPoint';
import { COMMON_CODES } from '../../../constants/StatusCode';
import { setModal } from '../../../stores/actions/Modal';


// ----------------------------------------------------------------------

export default function MoreMenu({ _id, setIsLoading, isVerifiedByAdmin, update, setUpdate, status }) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const pay = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(REFERRALS.PAY, _id, '');
    if (response) {
      if (response && response.status === COMMON_CODES.SUCCESS) {
        setUpdate(!update);
      } else {
        dispatch(setModal(response));
      }
    }
    setIsLoading(false);

  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20}/>
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => !status ? pay() : ''}>
          <ListItemIcon>
            <Icon icon={checkMarkFill} width={24} height={24} color={status ? '#229a16' : '#637381'}/>
          </ListItemIcon>
          <ListItemText primary={status ? 'Paid' : 'Pay'} primaryTypographyProps={{ variant: 'body2' }}/>
        </MenuItem>
      </Menu>
    </>
  );
}
