import React from "react";
import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import configureStore from "./stores/Store";
import {isLoggedIn} from "./services/Authorize";

const renderApp = preloadedState => {
    const store = configureStore(preloadedState);
    window.state = store.getState;
    ReactDOM.render(
        <Provider store={store}>
            <HelmetProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            </HelmetProvider>,
        </Provider>,
        document.getElementById("root")
    );
};

(async () => renderApp(await isLoggedIn()))();

/*
ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
*/

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
