export const VALIDATION_RULES = {
  EMAIL_REGEX: 'matchRegexp:^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$',
  PHONE_REGEX: 'matchRegexp:^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$',
  POSTCODE_REGEX: 'matchRegexp:^[0-9][0-9]{0,10}[0-9]$'
};

const USER_TYPE = {
  FREELANCER: 'freelancer',
  COMPANY_STANDARD: 'companyStandard',
  COMPANY_VENDOR: 'companyVendor',
  COMPANY: 'company',
  COMPANY_ADMIN: 'administrator'
};
export const EDITOR = 'Editor';

export const NOTIFICATION_STATUS = {
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
};

export const CONNECT = 'connect';
export const COMPLETE = 'complete';
export const LIVE = 'live';
export const CANCELLED = 'cancelled';
export const CONFIRMED = 'confirmed';
export const UNCONFIRMED = 'unconfirmed';
export const DECLINED = 'declined';
export const ACCEPTED = 'accepted';
export const UNACCEPTED = 'unaccepted';


export const TEXT_FIELD_MAX_LENGTH = 50;

export const MAX_IMAGE_UPLOAD_SIZE = 0.8 * 1000 * 1024;

export const USER_INACTIVE = 0;
export const USER_ACTIVE = 1;
export const USER_BLOCKED_BY_ADMIN = 2;

export const POST_ACTIVE = true;
export const POST_BLOCKED = false;

export const DATE_FORMAT = 'DD MMMM YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD MMMM YYYY HH:mma';

export const BANKING_STATUS_NONE = "none";
export const BANKING_STATUS_IN_REVIEW = "inReview";
export const BANKING_STATUS_ACCEPTED = "accepted";
export const BANKING_STATUS_DECLINED = "declined";

export const PAYOUT_STATUS_REQUESTED = "requested";
export const PAYOUT_STATUS_PAID = "paid";
export const PAYOUT_STATUS_REJECTED = "rejected";

export const MASSPAY_EXPORT_PENDING = "pending";
export const MASSPAY_EXPORT_PAID = "paid";





