import React from 'react';
import { useDispatch,useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Paper, IconButton, Typography, colors
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import {clearNotify} from "../../stores/actions/Notify";


import {GENERAL} from '../../constants/ApiResponseFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 20,
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    backgroundColor: colors.green[600],
    color: theme.palette.common.white
  },
  info: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.common.white
  },
  warning: {
    backgroundColor: colors.orange[900],
    color: theme.palette.common.white
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  message: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    fontSize: 12
  },
  icon: {
    fontSize: 13,
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8
  }
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />
};

const Alert = (props) => {
  const {
    className, icon  , onClose, ...rest
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useSelector(state => state.notify)
  const handleClose = () =>  () => {
    dispatch(clearNotify());
  };
  if(notify === null){
    return '';
  }
  let {variant}= props;
  const {message} = notify; console.log(notify,'notify')
  if(notify.status === 200){
    variant = 'success';
  }else if(notify.status === 500 || notify.status === 503 ){
    variant = 'warning';
  }
  else{
    variant = 'error';
  }

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, classes[variant], className)}
      component={Typography}
      elevation={1}
    // ref={ref}
      variant="outlined"
    >
      <span className={classes.icon}>{icon || icons[variant]}</span>
      <span className={classes.message}>{message}</span>

        <IconButton
          className={classes.action}
          color="inherit"
          key="close"
          onClick={handleClose()}
        >
          <CloseIcon  fontSize="small" />
        </IconButton>
    </Paper>
  );
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  // message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error'])
};

Alert.defaultProps = {
  variant: 'default'
};

export default Alert;

