import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
// material
import { CircularProgress, Grid, MenuItem, Slider, Stack, TextField, Typography } from '@material-ui/core';
import { Autocomplete, LoadingButton } from '@material-ui/lab';
import Box from '@mui/material/Box';
// components
//
import { fetchRecord, patchRecord } from '../services/Axios';
import { SPENDERS, USER } from '../constants/ApiEndPoint';
import { SPENDERS_ROUTES, USER_ROUTES } from '../constants/SiteRoutes';
import { prepareQueryParams } from '../utils/General';
import { setFlash } from '../stores/actions/Flash';
import { setModal } from '../stores/actions/Modal';

// ----------------------------------------------------------------------

export default function AddSubscribers(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const {totalDocs,subscriberFilterName} = props;

  const [filter, setFilter] = useState('all');
  const [update, setUpdate] = useState(false);
  const [recentSpenders, setRecentSpenders] = useState(pathName === SPENDERS_ROUTES.RECENT);
  const [creatorsLoader, setCreatorsLoader] = useState(false);
  const [type, setType] = useState('free');
  const [value, setValue] = React.useState([0, 0]);
  const options = [
    { value: 'free', label: 'Free' }
  ];

  const [fromRange, setFromRange] = useState(0);
  const [toRange, setToRange] = useState(totalDocs);


  useEffect(() => {

    setRecentSpenders(pathName === SPENDERS_ROUTES.RECENT);
    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const [creators, setCreators] = useState([]);
  const [creator, setCreator] = useState('');

  const listCreators = async () => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      sortColumn: 'displayName',
      searchText: filterName,
      creators: true,
      filter
    };
    setIsLoading(true);
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200 && response.docs && response.data.docs) {
        setCreators(response.data.docs);
      }
      setIsLoading(false);
    }
  };

  const onInputChange = async (event, value) => {
    const queryParams = {
      page: 1,
      perPageLimit: 10,
      creators: true,
      filter
    };
    if (event && (event.type === 'click' || event.key === 'Enter')) {
      value = '';
    }
    queryParams.searchText = value;
    const response = await fetchRecord(USER.LIST, '', '', prepareQueryParams(queryParams));
    if (response.status === 200) {
      setCreators(response.data.docs);
    }
  };

  useEffect(() => {
    listCreators().then().catch().finally();
  }, []);

  const onChange = (event, value) => {

    setCreator(value);
  };

  const getType = () => {
    if(pathName === USER_ROUTES.WITH_VALID_CARD){
      return 'usersWithValidCard';
    }
    if(pathName === USER_ROUTES.WITHOUT_VALID_CARD){
      return 'usersWithOutValidCard';
    }
    if(pathName === USER_ROUTES.ALL){
      return 'allUsers';
    }

  }

  const handleSubmit = async () => {
    // console.log(value, value[0], value[1]);
    const min = fromRange;
    const max = toRange;
    if (max === 0) {
      dispatch(setModal({ status: 401, message: 'Please select users range' }));
      return;
    }

    if ((max - min) > 5000) {
      dispatch(setModal({ status: 401, message: 'Please select less than 5000 users for easy processing.' }));
      return;
    }


    const body = {
      creator: creator._id,
      range: [fromRange, toRange],
      type: getType(),
      customers:true,
      searchText : subscriberFilterName
    };
    setIsLoading(true);
    const response = await patchRecord(SPENDERS.ADD_SUBSCRIBERS, body, '');
    setIsLoading(false);
    if (response) {
      setCreator(null);
      dispatch(setFlash(response));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <Stack style={{ margin: '5px 20px 5px 20px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6" gutterBottom>
          Add Subscribers
        </Typography>
      </Stack>
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography gutterBottom>Users Range</Typography>
            <Box>
              <TextField label="From" variant="outlined" InputLabelProps={{ shrink: true, }} style={{width: "80px"}} value={fromRange} onChange={(e) => {setFromRange(e.target.value);}}/>
              <TextField label="To" variant="outlined" InputLabelProps={{ shrink: true, }} style={{width: "80px", marginLeft: 10}} value={toRange} onChange={(e) => {setToRange(e.target.value);}}/>
            </Box>
            {/* <Slider
              getAriaLabel={() => 'Spenders Range'}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              max={totalDocs}
            /> */}

          </Grid>
          <Grid item xs={4} style={{marginTop: "30px"}}>
            <Autocomplete
              id="creator"
              name="creator"
              value={creator}
              onInputChange={onInputChange}
              onChange={(event, value) => setCreator(value)}
              options={creators}
              getOptionLabel={option =>
                option.displayName ? option.displayName : option
              }
              renderInput={params => (
                <TextValidator
                  {...params}
                  variant="outlined"
                  fullWidth
                  label="Creator"
                  placeholder="Creator"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={creator && creator.displayName}
                  validators={['required']}
                  errorMessages={['Creator is required.']}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {creatorsLoader ? <CircularProgress color="inherit" size={20}/> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{marginTop: "30px"}}>
            <TextField
              select
              value={type}
              onChange={onChange}
              fullWidth
              variant="outlined"
              label="Type"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={2} style={{marginTop: "30px"}}>
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              loading={loading}
            >
              Add Subscribers
            </LoadingButton>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Stack>
  );
}
