import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {clearNotify} from "../../stores/actions/Notify";
import {clearFlash} from "../../stores/actions/Flash";

const ClearNotify = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNotify());
    dispatch(clearFlash());
  }, []);

  return  '';
}

export default ClearNotify;

