import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import { getMp4Uri } from '../services/GeneralService';


const useStyles = makeStyles(theme => ({
  mediaWrapper: {
    margin: 10,
  },
  imageClass:{
    width: '60%',
    left: '50%',
    margin:'auto'
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%'
  },
  responsiveIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%'
  }

}));
export default function MyFrame(props) {
  const classes = useStyles();
  const { open, setOpen, handleClickOpen, handleClose, media } = props;
  /*  const [open, setOpen] = React.useState(false); */
  const theme = useTheme();


  /* const handleClickOpen = () => {
     setOpen(true);
   };

   const handleClose = () => {
     setOpen(false);
   }; */

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen
      >
        <DialogTitle id="responsive-dialog-title">Detail</DialogTitle>
        <DialogContent className={classes.mediaWrapper}>
          <div className={classes.imageClass}>
            {media && media.type === 'PHOTO' && <img src={media.link} alt=""  />}
            {media && media.type === 'VIDEO' &&
            <video width="100%" controls >
              <source src={getMp4Uri(media.link)} type="video/mp4" />
                <source src={media.link} />
              <track src="" kind="captions" srcLang="en" label="english_captions"/>
            </video>
            }
            {media && media.type === 'AUDIO' &&
            <audio controls src={media.link} >
              <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
            </audio>
            }
            {media && media.type === 'IFRAME' &&
              <>
                <div className={classes.container}>
                  <iframe
                    id="frame11"
                    title="frame11"
                    className={classes.responsiveIframe}
                    src={media.link} />
                </div>
                {/* <iframe id="frame11" title="frame11" src={media.link} width="100%" /> */}
                <a href={media.link} target="_blank" rel="noreferrer">Open in new tab</a>
              </>

            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
