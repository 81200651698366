import { SET_FLASH,CLEAR_FLASH } from "../../actions/Flash";
import {GENERAL} from "../../../constants/ApiResponseFormat";

const _body = GENERAL;
_body.message = 'Something went wrong.'
export default (state = null, { body, type }) => {
  Object.freeze(state);
  switch (type) {
    case SET_FLASH:
      return body || _body;
    case CLEAR_FLASH:
      return null;
    default:
      return state;
  }
};
