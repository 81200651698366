import React, { useEffect, useState } from 'react';
import { Card, CardContent, Container, Stack } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { LoadingButton } from '@material-ui/lab';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import Page from '../components/Page';
import { fetchRecord, patchRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { PAGE } from '../constants/ApiEndPoint';

const useStyles = makeStyles((theme) => ({
  demoEditorCustom: {
    height: '275px !important',
    border: '1px solid #F1F1F1 !important',
    padding: '5px !important',
    borderRadius: '2px !important'
  },
  backdropWrap: {
    position: 'relative'
  }
}));

export default function Content() {
  const classes = useStyles();
  const [loading, setIsLoading] = useState(false);
  const [type, setType] = useState('TermsAndCondition');
  const [content, setContents] = useState('');
  const onChange = (e) => {
    setType(e.target.value);
  };
  const [description, setDescription] = useState('');

  const getUrl = (type) => {
    if (type === 'TermsAndCondition') {
      return PAGE.TNC;
    }
    if (type === 'PrivacyPolicy') {
      return PAGE.PRIVACY_POLICY;
    }
    if (type === 'support') {
      return PAGE.SUPPORT;
    }
    if (type === 'creatorAgreement') {
      return PAGE.CREATOR_AGREEMENT;
    }
    if (type === 'helpCentre') {
      return PAGE.HELP_CENTRE;
    }
    if (type === 'ourMission') {
      return PAGE.OUR_MISSION;
    }
    if (type === 'ComplaintsPolicy') {
      return PAGE.COMPLAINS_POLICY;
    }
    if (type === 'ContentRemovalProcess') {
      return PAGE.CONTENT_REMOVAL_PROCESS;
    }
    if (type === 'Contact') {
      return PAGE.CONTACT;
    }
  };

  const getPage = async () => {
    setIsLoading(true);
    const page = getUrl(type);
    const response = await fetchRecord(page, '', '');
    if (response) {
      if (response.status === 200) {
        const contentBlock = htmlToDraft(response.data.content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
            contentBlock.entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          setContents(editorState);
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPage().then();
  }, [type]);

  const options = [
    { value: 'TermsAndCondition', label: 'Terms And Condition' },
    { value: 'PrivacyPolicy', label: 'Privacy Policy' },
    { value: 'support', label: 'Support' },
    { value: 'creatorAgreement', label: 'Content Creator Agreement' },
    { value: 'helpCentre', label: 'Help Centre' },
    { value: 'ourMission', label: 'Our Mission' },
    { value: 'ComplaintsPolicy', label: 'Complaints Policy'},
    { value: 'ContentRemovalProcess', label: 'Content Removal Process'},
    { value: 'Contact', label: 'Contact'},
  ];

  const handleSubmit = async () => {
    const body = {
      content: draftToHtml(convertToRaw(content.getCurrentContent()))
    };
    const response = await patchRecord(PAGE.SAVE_CONTENT, body, type);
  };

  const handleEditorStateChange = (content) => {
    setContents(content);
    // console.log(draftToHtml(convertToRaw(content.getCurrentContent())));
  };

  return (
    <Page title="Posts">
      <Container>
        {loading && <SimpleBackdrop />}
        <Card>
          <CardContent>
            <ValidatorForm onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField select value={type} onChange={onChange}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Editor
                  wrapperClassName="demo-wrapper"
                  editorClassName={classes.demoEditorCustom}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'colorPicker',
                      'remove',
                      'history'
                    ],
                    blockType: {
                      inDropdown: true,
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined
                    }
                  }}
                  editorState={content}
                  onEditorStateChange={handleEditorStateChange}
                />

                <LoadingButton size="medium" type="submit" variant="contained" loading={loading}>
                  Save
                </LoadingButton>
              </Stack>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
