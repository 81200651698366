export const SET_MODAL = "SET_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";

export const setModal= ( body ) => (
    {
      type: SET_MODAL,
      body
    }
);

export const clearModal = () => ({
  type: CLEAR_MODAL
});
