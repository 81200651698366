import * as Authorize from '../../services/Authorize';
import * as Storage from '../../services/Storage';
import {setNotify} from "./Notify";

export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";

export const receiveCurrentUser = user => ({
    type: RECEIVE_CURRENT_USER,
    user
});

const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER
});

export const verifyUser = (user) => async (dispatch) => {
  const response = await Authorize.verifyUser(user);
  if (response.status === 200) {
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const verifyCode = (user) => async (dispatch) => {
  const response = await Authorize.verifyCode(user);
  if (response.status === 200) {
    await Storage.clearAccessToken();
    await Storage.setAccessToken(response.data.tokenData.accessToken);
    dispatch(receiveCurrentUser(response.data));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const login = user => async dispatch => {
    const response = await Authorize.login(user);console.log(response,'happy');
    if (response.status === 200) {
        await Storage.clearAccessToken();
        await Storage.setAccessToken(response.data.tokenData.accessToken);
        dispatch(receiveCurrentUser(response.data));
        return response;
    }

    dispatch(setNotify(response));
  console.log('response',response)
    return response;
};

export const accountLogin = response => async dispatch => {
  if (response) {
    await Storage.clearAccessToken();
    await Storage.setAccessToken(response.tokenData.accessToken);
    dispatch(receiveCurrentUser(response));
    return response;
  }

  dispatch(setNotify(response));
  return response;
};

export const register = user => async dispatch => {
    const response = await Authorize.signup(user);
    if (response.status === 200) {
        // return dispatch(setNotify(response));
    }
    dispatch(setNotify(response));
    return response;
};

export const logout = () => async dispatch => {
    const response = await Authorize.logout();
    await Storage.clearAccessToken();
    if (response) {
         dispatch(logoutCurrentUser());
    }
    else if(response && response.status === 401){
      dispatch(logoutCurrentUser());
    }
    window.state().authorize = false;
    dispatch(setNotify(response));
};

function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
