import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import { Stack } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import SimpleBackdrop from '../../common/SimpleBackdrop';

import ClearNotify from '../../common/ClearNotify';
import Notify from '../../common/Notify';
import { login } from '../../../stores/actions/Authorize';
import { DASHBOARD_ROUTES } from '../../../constants/SiteRoutes';
import { fetchRecord, postRecord } from '../../../services/Axios';
import { USER } from '../../../constants/ApiEndPoint';
import { setFlash } from '../../../stores/actions/Flash';



const useStyles = makeStyles(theme => ({
  mediaWrapper: {
    margin: 10
  }

}));
export default function ApplicationCharge(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, setOpen,userId,setUpdate,update,setIsLoading} = props;
  const [applicationCharge, setApplicationCharge] = useState(props.applicationCharge || 20);
  const theme = useTheme();

  const handleSubmit = async() => {
    const body = {applicationCharge};
    setIsLoading(true);
    setOpen(false);
    const response = await postRecord( USER.APPLICATION_CHARGE,body,userId,);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
      dispatch(setFlash(response));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = [
    {
      label:20,
      value:20,
    },
    {
      label:30,
      value:30,
    },
    {
      label:40,
      value:40,
    },
    {
      label:50,
      value:50,
    },
    {
      label:60,
      value:60,
    },
    {
      label:70,
      value:70,
    },
    {
      label:80,
      value:80,
    },
    {
      label:90,
      value:90,
    },
  ]

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Application Charge</DialogTitle>
        <DialogContent className={classes.mediaWrapper}>
          <ValidatorForm onSubmit={handleSubmit}>
            <ClearNotify/>
            <Notify/>
              <TextValidator
                select
                label="Application Charge"
                onChange={e => setApplicationCharge(e.target.value)}
                placeholder="Application Charge"
                helperText=""
                fullWidth
                name="applicationCharge"
                type="text"
                margin="normal"
                value={applicationCharge}
                validators={['required', 'isNumber',"minNumber:20","maxNumber:99"]}
                errorMessages={[
                  'Number is required.',
                  'Must be a valid number.',
                  'Must be greater than 20.',
                  'Must be less than 99.',
                ]}
                // variant="outlined"
                autoComplete="applicationCharge"
                autoFocus
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextValidator>
          </ValidatorForm>

        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
