import {Icon} from '@iconify/react';
import React, {useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkMarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import personDoneOutline from '@iconify/icons-eva/person-done-outline';
import personDeleteOutline from '@iconify/icons-eva/person-delete-outline';
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import briefcaseFill from "@iconify/icons-eva/briefcase-fill";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";
import briefcaseOutline from "@iconify/icons-eva/briefcase-outline";
import fileRemoveFill from "@iconify/icons-eva/file-remove-fill";
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
// material
import {Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import {setModal} from "../../../stores/actions/Modal";
import {fetchRecord, postRecord} from '../../../services/Axios';
import {BANKING_REVIEW, USER} from '../../../constants/ApiEndPoint';
import {DATE_FORMAT, USER_ACTIVE, USER_INACTIVE} from '../../../constants';
import ApplicationCharge from './ApplicationCharge';
import ChangePassword from './ChangePassword';
import BankingDetails from "./BankingDetails";
import Label from "../../Label";
import PlayMedia from "../../PlayMedia";
import Earnings from "./Earnings";

// ----------------------------------------------------------------------

export default function UserMoreMenu({
                                       userId,
                                       applicationCharge,
                                       setIsLoading,
                                       isVerifiedByAdmin,
                                       update,
                                       setUpdate,
                                       status,
                                       user,
                                       isCreator,
                                       isReviewPage,
                                       requestId,
                                       bankingDetails,
                                       isFavourite,
                                     }) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isApplicationChargeOpen, setIsApplicationChargeOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [bankingOpen, setBankingOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  const verify = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(USER.VERIFY, userId, '');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const markAsFavourite = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const favourite = isFavourite ? 0 : 1;
    const response = await postRecord(USER.MARK_AS_FAVOURITE, {}, userId, favourite);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const block = async (block = true) => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(block ? USER.BLOCK : USER.UNBLOCK, userId, '');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const addOrRemoveAsCreator = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(isCreator ? USER.REMOVE_AS_CREATOR : USER.ADD_AS_CREATOR, userId, '');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const acceptAsCreator = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(status === 'accepted' ? BANKING_REVIEW.REMOVE_AS_CREATOR : BANKING_REVIEW.ADD_AS_CREATOR, requestId);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const rejectAsCreator = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord( BANKING_REVIEW.REMOVE_AS_CREATOR, requestId);
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const verifyEmail = async () => {
    setIsLoading(true);
    setIsOpen(false);
    const response = await fetchRecord(USER.VERIFY_EMAIL, userId, '');
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const showApplicationChargeModal = () => {
    setIsApplicationChargeOpen(true);
    setIsOpen(false);
  };

  const showPasswordChangeModal = () => {
    setChangePasswordOpen(true);
    setIsOpen(false);
  };

  const showBankDetailsModal = () => {
    setBankingOpen(true);
    setIsOpen(false);
  };

  const showEarnings = () => {
    setStatsOpen(true);
    setIsOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  function bankInfo(bankingDetails) {
    if (!bankingDetails) {
      return (
        <>
          User has not provided bank details.
        </>
      )
    }
    return (
      <table>
        <tr>
          <td>&nbsp;</td>
          <td>BANK DETAILS</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          <td>Full name:</td>
          <td> {bankingDetails.fullName}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td> {bankingDetails.address}</td>
        </tr>
        <tr>
          <td>Bank:</td>
          <td> {bankingDetails.bankDetails}</td>
        </tr>
        <tr>
          <td>Bank Name:</td>
          <td> {bankingDetails.bankName}</td>
        </tr>
        <tr>
          <td>Account Name:</td>
          <td> {bankingDetails.accountName}</td>
        </tr>
        <tr>
          <td>Account Number:</td>
          <td> {bankingDetails.accountNumber}</td>
        </tr>
        <tr>
          <td>BSB:</td>
          <td> {bankingDetails.bsb}</td>
        </tr>
        <tr>
          <td>Dob:</td>
          <td>
            <Label variant="ghost" color="info">
              {moment(bankingDetails.dateOfBirth).format(DATE_FORMAT)}
            </Label>
          </td>
        </tr>
        <tr>
          <td>ID:</td>
          <td>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleMedia(bankingDetails.idUrl)}
              endIcon={<AttachFileIcon/>}
            >
              View
            </Button>
          </td>
        </tr>

      </table>
    );
  }

  const viewBank = () => {
    setIsOpen(false);
    dispatch(setModal({status: 401, message: bankInfo(bankingDetails)}));
  };

  function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  const handleMedia = (link) => {

    setMedia({
      type: isImage(link) ? 'PHOTO' : 'IFRAME',
      link
    });
    setOpen(true);
  };

  if (isReviewPage) {
    return (
      <>
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Icon icon={moreVerticalFill} width={20} height={20}/>
        </IconButton>

        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: {width: 200, maxWidth: '100%'}
          }}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >

          <MenuItem sx={{color: 'text.secondary'}} onClick={acceptAsCreator}>
            <ListItemIcon>
              <Icon icon={checkMarkFill} width={24} height={24} color="#229a16"/>
            </ListItemIcon>
            <ListItemText primary="Accept" primaryTypographyProps={{variant: 'body2'}}/>
          </MenuItem>
          <MenuItem sx={{color: 'text.secondary'}} onClick={rejectAsCreator}>
            <ListItemIcon>
              <Icon icon={closeCircleFill} width={24} height={24} color="#637381"/>
            </ListItemIcon>
            <ListItemText primary="Reject" primaryTypographyProps={{variant: 'body2'}}/>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20}/>
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {width: 200, maxWidth: '100%'}
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <MenuItem sx={{color: 'text.secondary'}} onClick={markAsFavourite}>
          <ListItemIcon>
            <Icon icon={checkMarkFill} width={24} height={24} color={isFavourite ? "#229a16" : "#637381"}/>
          </ListItemIcon>
          <ListItemText primary={isFavourite ? "Remove Favourite" : "Mark As Favourite"} primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>

        <MenuItem sx={{color: 'text.secondary'}} onClick={verify}>
          <ListItemIcon>
            <Icon icon={checkMarkFill} width={24} height={24} color={isVerifiedByAdmin ? "#229a16" : "#637381"}/>
          </ListItemIcon>
          <ListItemText primary={isVerifiedByAdmin ? "Verified" : "Verify"}
                        primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        <MenuItem sx={{color: 'text.secondary'}} onClick={() => status === USER_ACTIVE ? block(true) : block(false)}>
          <ListItemIcon>
            <Icon icon={status === USER_ACTIVE ? personDeleteOutline : personDoneOutline} width={24} height={24}
                  color={status === USER_ACTIVE ? "#B72136 " : "#229a16"}/>
          </ListItemIcon>
          <ListItemText primary={status === USER_ACTIVE ? "Block" : "Unblock"}
                        primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        <MenuItem sx={{color: 'text.secondary'}} onClick={() => showApplicationChargeModal()}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24}/>
          </ListItemIcon>
          <ListItemText primary="Application Charge" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        <MenuItem sx={{color: 'text.secondary'}} onClick={() => showPasswordChangeModal()}>
          <ListItemIcon>
            <Icon icon={eyeFill} width={24} height={24}/>
          </ListItemIcon>
          <ListItemText primary="Change Password" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        {status === USER_INACTIVE &&
          <MenuItem sx={{color: 'text.secondary'}} onClick={() => verifyEmail()}>
            <ListItemIcon>
              <Icon icon={personDoneOutline} width={24} height={24} color="#B72136"/>
            </ListItemIcon>
            <ListItemText primary="Set Email Verified" primaryTypographyProps={{variant: 'body2'}}/>
          </MenuItem>}
        {/*        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        {/*     <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        <MenuItem sx={{color: 'text.secondary'}} onClick={addOrRemoveAsCreator}>
          <ListItemIcon>
            <Icon icon={checkMarkFill} width={24} height={24} color={isCreator ? "#637381" : "#229a16"}/>
          </ListItemIcon>
          <ListItemText primary={isCreator ? "Remove as Creator" : "Add As Creator"}
                        primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        {isCreator &&
          <>
            <MenuItem sx={{color: 'text.secondary'}} onClick={() => viewBank(bankingDetails)}>
              <ListItemIcon>
                <Icon icon={briefcaseFill} width={24} height={24} color="#B72136"/>
              </ListItemIcon>
              <ListItemText primary="View Bank Details" primaryTypographyProps={{variant: 'body2'}}/>
            </MenuItem>
            <MenuItem sx={{color: 'text.secondary'}} onClick={() => showBankDetailsModal()}>
              <ListItemIcon>
                <Icon icon={briefcaseOutline} width={24} height={24}/>
              </ListItemIcon>
              <ListItemText primary="Edit Bank Details" primaryTypographyProps={{variant: 'body2'}}/>
            </MenuItem>
            <MenuItem sx={{color: 'text.secondary'}} onClick={() => showEarnings()}>
              <ListItemIcon>
                <Icon icon={bookOpenFill} width={24} height={24}/>
              </ListItemIcon>
              <ListItemText primary="Earnings Stats" primaryTypographyProps={{variant: 'body2'}}/>
            </MenuItem>
          </>
        }

      </Menu>
      {isApplicationChargeOpen &&
        <ApplicationCharge
          userId={userId}
          applicationCharge={applicationCharge}
          open={isApplicationChargeOpen}
          setOpen={setIsApplicationChargeOpen}
          setUpdate={setUpdate}
          update={update}
          setIsLoading={setIsLoading}
        />
      }
      {changePasswordOpen &&
        <ChangePassword
          user={user}
          userId={userId}
          open={changePasswordOpen}
          setOpen={setChangePasswordOpen}
          setUpdate={setUpdate}
          update={update}
          setIsLoading={setIsLoading}
        />
      }
      {bankingOpen &&
        <BankingDetails
          user={user}
          userId={userId}
          open={bankingOpen}
          setOpen={setBankingOpen}
          setUpdate={setUpdate}
          update={update}
          setIsLoading={setIsLoading}
        />
      }
      {open &&
        <PlayMedia
          media={media}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
        />
      }
      {statsOpen &&
        <Earnings
          user={user}
          userId={userId}
          open={statsOpen}
          setOpen={setStatsOpen}
          setUpdate={setUpdate}
          update={update}
          setIsLoading={setIsLoading}
        />
      }
    </>
  );
}
