import Configs from '../configs/Configs';
import {fetchRecord, postRecord} from './Axios';
import {AUTHENTICATION} from "../constants/ApiEndPoint";

/*
export const login = async (user) => {
    await postRecord(AUTHENTICATION.LOGIN,user);
};

*/

export const verifyUser = async (user) => {
  const response = await postRecord(AUTHENTICATION.VERIFY_USER, user);
  return response;
};

export const verifyCode = async (user) => {
  const response = await postRecord(AUTHENTICATION.VERIFY_CODE, user);
  return response;
};

export const login = async (user) => {
    const response = await postRecord(AUTHENTICATION.LOGIN, user);
    return response;
}

export const signup = async (user) => {
    await postRecord(AUTHENTICATION.REGISTER, user)
};


export const logout = () => (
    postRecord(AUTHENTICATION.LOGOUT)
);

export const verify = async (code) => {
     await fetchRecord(AUTHENTICATION.EMAIL_VERIFY,code);
};

export const forgotPassword = user => (
    postRecord(AUTHENTICATION.LOGIN, user)
)

export const isLoggedIn = async () => {
    const response = await fetchRecord(AUTHENTICATION.USER_PROFILE);
    let user = null;
    if(response && response.data && response.data._id){
       user = response.data;
    }

    let preloadedState = {};
    if (user) {
        preloadedState = {
            authorize: user
        };
    }

    return preloadedState;
};
