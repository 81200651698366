import {Icon} from '@iconify/react';
import React, {useRef, useState} from 'react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-circle-2-fill';

// material
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import {postRecord} from '../../../services/Axios';
import {MASSPAY_REQUESTS} from '../../../constants/ApiEndPoint';
import {
  MASSPAY_EXPORT_PENDING,
  MASSPAY_EXPORT_PAID,
  POST_ACTIVE,
} from '../../../constants';
import PlayMedia from "../../PlayMedia";

// ----------------------------------------------------------------------

export default function PayoutMoreMenu({requestId, setIsLoading, update, setUpdate, status}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const markAsPaid = async (markAs = true) => {
    setIsLoading(true);
    setIsOpen(false);
    let type = MASSPAY_REQUESTS.MARK_AS_PAID;
    if (!markAs) {
      type = MASSPAY_REQUESTS.MARK_AS_UNPAID;
    }
    const response = await postRecord(type, {requestId});
    if (response) {
      setUpdate(!update);
      setIsLoading(false);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20}/>
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {width: 200, maxWidth: '100%'}
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >

        <MenuItem sx={{color: 'text.secondary'}} onClick={() => status === MASSPAY_EXPORT_PENDING ? markAsPaid(true) : markAsPaid(false)}>
          <ListItemIcon>
            <Icon icon={status === POST_ACTIVE ? checkmarkFill : checkmarkFill} width={24} height={24}
                  color={status === MASSPAY_EXPORT_PENDING ? "#229a16" : "#B72136"}/>
          </ListItemIcon>
          <ListItemText primary={status === MASSPAY_EXPORT_PAID ? "Mark as Unpaid" : "Mark As Paid"} primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      </Menu>

      {open &&
        <PlayMedia
          media={media}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
        />
      }
    </>
  );
}
