import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
// material
import {
  Accordion,
  AccordionSummary,
  Button,
  Card,
  Container,
  Divider, MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Box } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../components/_dashboard/user';
//
import { fetchRecord, postRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { PLATFORM_FEES } from '../constants/ApiEndPoint';
import {DATE_FORMAT, USER_ACTIVE} from '../constants';
import { USER_ROUTES } from '../constants/SiteRoutes';
import { prepareQueryParams } from '../utils/General';
import { setFlash } from '../stores/actions/Flash';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }

}));
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'year', label: 'Year', alignRight: false },
  { id: 'month', label: 'Month', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'amountPaid', label: 'Amount', alignRight: false },
  { id: 'numberOfUsers', label: 'Users Count', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PlatformFeesInfos() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setIsLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter, setFilter] = useState('all');
  const [update, setUpdate] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment().subtract(1, 'months'));
  const [newCreatorsPage, setNewCreatorsPage] = useState(pathName === USER_ROUTES.NEW_CREATORS);
  const [feeDate, handleFeeDateChange] = useState(new Date());
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(eRows);
  }, [page, rowsPerPage]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, 'newPage');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };

  useEffect(() => {
    setNewCreatorsPage(pathName === USER_ROUTES.NEW_CREATORS);
    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const fetchRows = async () => {
    const queryParams = {
      page: page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      newCreatorsPage,
      filter,
      selectedDate,
      year: moment(selectedDate).format('YYYY'),
      month: moment(selectedDate).format('M')
    };
    setIsLoading(true);
    const response = await fetchRecord(PLATFORM_FEES.GET_PLATFORM_FEE_INFOS, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setRows(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setRows([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRows().then().catch().finally();
  }, [
    page, filter, update, selectedDate
  ]);


  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'paid', label: 'Paid' },
    { value: 'unpaid', label: 'Un-Paid' }
  ];

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  const handleStart = async () => {
    const body = {
      year: moment(feeDate).format('YYYY'),
      month: moment(feeDate).format('M'),
      amount: Number(amount)
    };
    setIsLoading(true);
    const response = await postRecord(PLATFORM_FEES.SET, body);
    if (response) {
      dispatch(setFlash(response));
      if (response.status === 200) {
        setUpdate(!update);
      }
      
    }
    setIsLoading(false);
  };

  const [amount, setAmount] = useState(10);

  const numbers = [...new Array(50)];

  return (
    <Page title="PlatForm Fees">
      <Container>
        {loading && <SimpleBackdrop/>}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Platform fee
          </Typography>
        </Stack>

        <Card>
          <Box style={{ margin: '20px 20px 40px 20px' }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Setup Platform Fee</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div style={{display:'none'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={['month', 'year']}
                        label="Select Month to Trigger"
                        minDate={new Date()}
                        maxDate={new Date('2042-06-01')}
                        value={feeDate}
                        onChange={handleFeeDateChange}
                        renderInput={(props) => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                    </div>
                    <div>
                      <TextField
                        style={{width:200}}
                        size="large"
                        select
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        fullWidth
                        variant="outlined"
                        label="Amount"
                      >
                        {numbers.map((each, index) => (
                          <MenuItem value={index + 1}>{index + 1}</MenuItem>))
                        }
                      </TextField>
                    </div>


                    <Typography>
                      <Button
                        size="large"
                        variant="contained"
                        color="warning"
                        onClick={
                        () => {
                          if (window.confirm('Are you sure you want to start? It cannot be reversed once started!')) {
                            handleStart().then()
                          }
                        }
                      }
                      >
                        Trigger for {moment().format('MMMM')}&nbsp;{ moment().format('YYYY')}
                      </Button>
                    </Typography>
                    <Typography style={{marginLeft: 'auto'}}>
                      <Button  size="large" variant="contained" onClick={() => setUpdate(!update)}>
                        Reload Page
                      </Button>
                    </Typography>
                  </Stack>

                </Stack>

              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider light/>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {rows.map((row) => {
                    const {
                      _id,
                      amountPaid,
                      createdAt,
                      month,
                      numberOfUsers,
                      status,
                      year
                    } = row;


                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={false}
                        aria-checked={false}
                      >
                        <TableCell component="th" scope="row" padding="normal">
                          {year}
                        </TableCell>
                        <TableCell align="left">{moment(createdAt).format('MMMM')}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color="info">
                            {moment(createdAt).format(DATE_FORMAT)}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          ${amountPaid/100}
                        </TableCell>
                        <TableCell align="left">
                          {numberOfUsers}
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status  ? 'success' : 'error'}
                          >
                            {status === 0 && 'Queued'}
                            {status === 1 && 'Started'}
                            {status === 2 && 'Completed'}
                          </Label>

                        </TableCell>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6}/>
                    </TableRow>
                  )}
                </TableBody>
                {rows.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
