export const AUTH_ROUTES = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  EMAIL_VERIFY: '/auth/verify-email',
  LOGOUT: '/auth/logout',
  REGISTRATION_SUCCESS: '/auth/registration-success',
  RESET_PASSWORD_SUCCESS: '/auth/reset-password-success',
  VERIFY_CODE: '/auth/verify-code'
};

export const DASHBOARD_ROUTES = {
  STATS_INDEX: 'stats',
  STATS: '/dashboard/stats',
  USER: '/dashboard/user',

  USER_INDEX: 'user',
  POST: '/dashboard/post',
  POST_INDEX: 'post',
  REFERRALS: '/dashboard/referrals',
  CONTENT: '/dashboard/content',
  BANNED_WORDS: '/dashboard/banned-words',
  PLATFORM_FEES: '/dashboard/platform-fees',
  PLATFORM_FEES_INFOS: '/dashboard/platform-infos',
  PLATFORM_USERS: '/dashboard/platform-users',
  MASS_MESSAGES: '/dashboard/mass-messages',
  MEDIAS: '/dashboard/medias',
  ALL_MEDIAS: '/dashboard/all-medias',
  REVIEWS: '/dashboard/reviews',
  PAYOUTS: '/dashboard/payouts',
  TRANSACTIONS: '/dashboard/transactions',
  MASSPAYS: '/dashboard/masspays',
  MASSPAY_EXPORT: '/dashboard/masspay-exports',
};

export const USER_ROUTES = {
  INDEX: '/users',
  LIST_INDEX: 'list',
  LIST: '/users/list',
  NEW_CREATORS_INDEX: '/new-creators',
  NEW_CREATORS: '/users/new-creators',
  ALL_CREATORS_INDEX: '/all-creators',
  ALL_CREATORS: '/users/all-creators',
  FAVOURITE_CREATORS_INDEX: '/favourite-creators',
  FAVOURITE_CREATORS: '/users/favourite-creators',
  WITH_CARD: '/users/with-card',
  WITH_CARD_INDEX: '/with-card',
  WITH_VALID_CARD: '/users/with-valid-card',
  WITHOUT_VALID_CARD: '/users/without-valid-card',
  WITH_BANNED_WORDS: '/users/with-banned-words',
  WITH_VALID_CARD_INDEX: '/with-valid-card',
  WITHOUT_VALID_CARD_INDEX: 'without-valid-card',
  WITH_BANNED_WORDS_INDEX: 'with-banned-words',
  ALL_INDEX: 'all',
  ALL: '/users/all',
};

export const POST_ROUTES = {
  INDEX: '/posts',
  LIST_INDEX: 'list',
  LIST: '/posts/list',
  REPORTED_INDEX: '/reported',
  REPORTED: '/posts/reported',

};

export const SPENDERS_ROUTES = {
  INDEX: '/spenders',
  TOP_INDEX: '/top',
  TOP: '/spenders/top',
  RECENT_INDEX: '/recent',
  RECENT: '/spenders/recent',
  SUBSCRIBERS_INDEX: '/subscribers',
  SUBSCRIBERS: '/spenders/subscribers',
};






