import {combineReducers} from "redux";
import authorize from "./authorize/Authorize";
import notify from "./notify/Notify";
import flash from "./flash/Flash";
import global from "./global/Global";
import modal from "./modal/Modal";

export default combineReducers({
  authorize,
  notify,
  flash,
  global,
  modal
});
