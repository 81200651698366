import React from "react";
import Configs from '../configs/Configs';

export const connect = async (FanId) => {

  let response;

  return response;
};


export const getMp4Uri = url => {
  if(!url){
    return;
  }
  const originalUri = url;
  const parts = url.split('/');
  const key = parts[4];
  const keyParts = key.split('.');
  return `https://${Configs.AWS.BUCKET_MP4}.s3.amazonaws.com/transcoded/${keyParts[0]}.mp4`;
};




