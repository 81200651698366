import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow, TableCell, Checkbox, Avatar, TablePagination, Card
} from '@material-ui/core';
import AirplayIcon from '@material-ui/icons/Airplay';
import moment from 'moment';
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
import POSTS from '../_mocks_/blog';
import { fetchRecord } from '../services/Axios';
import { MEDIA } from '../constants/ApiEndPoint';
import { prepareQueryParams } from '../utils/General';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { MassMessageMoreMenu, MassMessageListHead, MassMessageListToolbar } from '../components/_dashboard/massMessage';
import Scrollbar from '../components/Scrollbar';
import Label from '../components/Label';
import { USER_ACTIVE } from '../constants';
import SearchNotFound from '../components/SearchNotFound';
import PlayMedia from '../components/PlayMedia';
import { POST_ROUTES, USER_ROUTES } from '../constants/SiteRoutes';
import Thumbnail from '../components/common/Thumbnail';



// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' }
];

const TABLE_HEAD = [
  { id: 'sentBy', label: 'User', alignRight: false },
  { id: 'mediaFiles', label: 'Media Files', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'uploadedFrom', label: 'Uploaded From', alignRight: false},
  { id: 'createdAt', label: 'Created At', alignRight: false },

];

// ----------------------------------------------------------------------

export default function Medias() {
  const location = useLocation();
  const pathName = location.pathname;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [emptyRows, setEmptyRows] = useState(eRows);
  const [filter, setFilter] = useState('all');
  const [update, setUpdate] = useState(false);
  const [creator, setCreator] = useState('');

  useEffect(() => {
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    setEmptyRows(eRows);
  }, [page, rowsPerPage]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setUpdate(!update);
  };

  const getRecords = async () => {
    const queryParams = {
      page: page + 1,
      perPageLimit: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      searchText: filterName,
      filter,
      reported: pathName === POST_ROUTES.REPORTED || null,
      creator: creator && creator._id
    };
    setIsLoading(true);
    const response = await fetchRecord(MEDIA.LIST, '', '', prepareQueryParams(queryParams));
    if (response) {
      if (response.status === 200) {
        setRows(response.data.docs);
        setTotalDocs(response.data.totalDocs);
      } else {
        setRows([]);
        setTotalDocs(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRecords().then().catch().finally();
  }, [
    page, filter, update, creator
  ]);

  useEffect(() => {

    setPage(0);
    setUpdate(!update);
  }, [
    pathName
  ]);

  const filterOptions = [
    { value: 'chat', label: 'Chat' },
    { value: 'post', label: 'Post' },
    { value: 'treasure', label: 'Treasure' },
    { value: 'all', label: 'All' },
  ];

  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedia(null);
  };

  const handleMedia = (media) => {
    setMedia(media);
    setOpen(true);
  };

  return (
    <Page title="Medias">
      <Container>
        {loading && <SimpleBackdrop />}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Medias
          </Typography>
        </Stack>

        <Card>
          <MassMessageListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterOptions={filterOptions}
            filter={filter}
            setFilter={setFilter}
            creator={creator}
            setCreator={setCreator}
            showSort
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MassMessageListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={rows.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {rows.map((row) => {
                    const user = {

                    };
                    const { 
                      _id, 
                      userInfo, 
                      duration,
                      link,
                      thumbnailLink,
                      type,
                      uploadedFrom,
                      createdAt 
                    } = row;
                    const media = {
                      duration,
                      link,
                      thumbnailLink,
                      type
                    };
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    if (!user) {
                      return;
                    }

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell component="th" scope="row" padding="normal" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={userInfo.displayName} src={userInfo.photoUri} />
                            <Typography variant="subtitle2" noWrap>
                              {userInfo.displayName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"> 
                          <div>
                            {/* <Thumbnail 
                              type={type}
                              url={link}
                            /> */}
                            <Button onClick={() => handleMedia(media)}>
                              <AirplayIcon />
                            </Button>
                          </div>
                        </TableCell>
                        <TableCell align="left">{type}</TableCell>
                        <TableCell align="left">{uploadedFrom.toUpperCase()}</TableCell>
                        <TableCell align="left">{moment(createdAt).format('DD-MM-YYYY')}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 3 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {rows.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        { /* <Grid container spacing={3}>
          {posts.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid> */}
        {open &&
          <PlayMedia
            media={media}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
          />
        }
      </Container>
    </Page>
  );
}
