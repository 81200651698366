const global = {
  lastLocation: '',
  historyAction: ''

};
export default (state = global, {type}) => {
  Object.freeze(state);

  return state;

};
