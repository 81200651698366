import {Icon} from '@iconify/react';
import React, {useRef, useState} from 'react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkMarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core';
import {postRecord} from '../../../services/Axios';
import {USER} from '../../../constants/ApiEndPoint';

export default function CreatorMoreMenu({ userId, setIsLoading, update, setUpdate, isFavourite, }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const markAsFavourite = async () => {
        setIsLoading(true);
        setIsOpen(false);
        const favourite = isFavourite ? 0 : 1;
        const response = await postRecord(USER.MARK_AS_FAVOURITE, {}, userId, favourite);
        if (response) {
            setUpdate(!update);
            setIsLoading(false);
        }
    };

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'}
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem sx={{color: 'text.secondary'}} onClick={markAsFavourite}>
                    <ListItemIcon>
                        <Icon icon={checkMarkFill} width={24} height={24} color={isFavourite ? "#229a16" : "#637381"}/>
                    </ListItemIcon>
                    <ListItemText primary={isFavourite ? "Remove Favourite" : "Mark As Favourite"} primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>
            </Menu>
        </>
    );
}
