import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkMarkFill from '@iconify/icons-eva/checkmark-circle-2-outline';
import alertCircleOutline from '@iconify/icons-eva/alert-circle-outline';
import personDeleteFill from '@iconify/icons-eva/person-delete-fill';
import personDoneOutline from '@iconify/icons-eva/person-done-outline';
import personDeleteOutline from '@iconify/icons-eva/person-delete-outline';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { setFlash } from '../../../stores/actions/Flash';
import { deleteRecord, fetchRecord } from '../../../services/Axios';
import { MASS_MESSAGE, POST, USER } from '../../../constants/ApiEndPoint';
import { POST_ACTIVE, USER_ACTIVE } from '../../../constants';


// ----------------------------------------------------------------------

export default function MassMessageMoreMenu({messageId,setIsLoading,update,setUpdate,isUnSent,deletedAt}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const unSendMessage = async () => {
    if(isUnSent){
      const body = {
        status:500,
        message:'Message Already Unsent'
      }
      dispatch(setFlash(body))
      return;
    }
    setIsLoading(true);
    setIsOpen(false);
     const response = await deleteRecord(MASS_MESSAGE.UNSEND,{},messageId);
     if (response) {
       setUpdate(!update);
      
     }
    setIsLoading(false);
  };


  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        <MenuItem  sx={{ color: 'text.secondary' }} onClick={() => unSendMessage(false) } >
          <ListItemIcon>
            <Icon icon={isUnSent ? refreshOutline  : closeCircleFill } width={24} height={24}  color={isUnSent ?  "#229a16":"#B72136 " }/>
          </ListItemIcon>
          <ListItemText primary={isUnSent ? "N/A" : "Unsend" } primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
