import { useLocation } from 'react-router';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import eyeOutline from '@iconify/icons-eva/arrow-ios-forward-outline';
import {
    Avatar,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    CardHeader,
    IconButton,
    TableHead,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, CreatorMoreMenu } from '../components/_dashboard/user';
import { fetchRecord, postRecord } from '../services/Axios';
import SimpleBackdrop from '../components/common/SimpleBackdrop';
import { USER } from '../constants/ApiEndPoint';
import { DATE_FORMAT, USER_ACTIVE } from '../constants';

export default function FavouriteUser() {
    const location = useLocation();
    const pathName = location.pathname;
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);
    const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
    const [emptyRows, setEmptyRows] = useState(eRows);
    const [filter, setFilter] = useState('all');
    const [update, setUpdate] = useState(false);
    const [creator, setCreator] = useState('0');
    const [creators, setCreators] = useState([]);

    const TABLE_HEAD = [
        { id: 'displayName', label: 'User', alignRight: false },
        { id: 'isVerified', label: 'Verified', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'applicationCharge', label: 'Fee', alignRight: false },
        { id: 'createdAt', label: 'Created At', alignRight: false },
    ];

    useEffect(() => {
        const eRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;
        setEmptyRows(eRows);
    }, [page, rowsPerPage]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.username);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    
    const handleCreatorSelect = (event) => {
        setCreator(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    useEffect(() => {
        setPage(0);
        setUpdate(!update);
    }, [pathName]);

    const listUsers = async () => {
        setIsLoading(true);
        const response = await fetchRecord(USER.LIST_FAVOURITE);
        if (response) {
            if (response.status === 200) {
                setUsers(response.data.docs);
                setTotalDocs(response.data.totalDocs);
            } else {
                setUsers([]);
                setTotalDocs(0);
            }
            setIsLoading(false);
        }
    };

    const listCreators = async () => {
        setIsLoading(true);
        const response = await fetchRecord(USER.LIST_CREATORS);
        if (response) {
            if (response.status === 200) {
                setCreators(response.data);
            } else {
                setCreators([]);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        listUsers().then().catch().finally();
        listCreators().then().catch().finally();
    }, [page, filter, update]);

    const markAsFavourite = async () => {
        if (creator === '0') {
            return;
        }
        setIsLoading(true);
        const response = await postRecord(USER.MARK_AS_FAVOURITE, {}, creator, 1);
        if (response) {
            setUpdate(!update);
            setIsLoading(false);
        }
    };

  return (
        <Page title="Favourite Creators">
            <Container>
                {loading && <SimpleBackdrop />}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Favourite Creators
                    </Typography>
                </Stack>

                <Card>
                    <Select
                        value={creator}
                        onChange={handleCreatorSelect}
                        style={{ margin: 10,minWidth: 120 }}
                    >
                        <MenuItem value="0">Select Creator</MenuItem>
                        {
                            creators.map((creator) => (
                                <MenuItem key={creator._id} value={creator._id}>
                                    {creator.displayName ? creator.displayName : creator.username}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <Button variant="contained" color="primary" onClick={markAsFavourite}>Add</Button>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={users.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {users.map((row) => {
                                        const { _id, displayName, username, email, status, photoUri, isVerifiedByAdmin, applicationCharge, createdAt, deletedAt, blockedAt, isCreator, bankingDetails, isFavourite } = row;
                                        const isItemSelected = selected.indexOf(username) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell component="th" scope="row" padding="normal">
                                                    <CardHeader
                                                        style={{ padding: 0 }}
                                                        avatar={<Avatar alt={displayName} src={photoUri} />}
                                                        title={
                                                        <Typography variant="subtitle2" noWrap>
                                                            {displayName}
                                                            &nbsp;
                                                            <Label variant="ghost" color={isCreator ? 'primary' : 'secondary'}>
                                                            {isCreator ? 'Creator' : 'User'}
                                                            </Label>
                                                        </Typography>
                                                        }
                                                        subheader={
                                                        <>
                                                            <Typography
                                                            variant="subtitle2"
                                                            noWrap
                                                            style={{ maxWidth: '200px' }}
                                                            >
                                                            {username}
                                                            </Typography>

                                                            <Typography variant="caption" noWrap>
                                                            {email}
                                                            </Typography>
                                                            <Tooltip title={email}>
                                                            <IconButton size="small">
                                                                <Icon icon={eyeOutline} />
                                                            </IconButton>
                                                            </Tooltip>
                                                        </>
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label variant="ghost" color={isVerifiedByAdmin ? 'success' : 'error'}>
                                                        {isVerifiedByAdmin && 'Verified'}
                                                        {!isVerifiedByAdmin && 'Not Verified'}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant="ghost"
                                                        color={status === USER_ACTIVE ? 'success' : 'error'}
                                                    >
                                                        {status === 0 && 'Un-Verified email'}
                                                        {status === 1 && 'Active'}
                                                        {status === 2 && 'Admin Deleted'}
                                                        {status === 3 && 'User Deleted'}
                                                    </Label>
                                                    {(status === 3 || status === 2) && (deletedAt || blockedAt) && (
                                                        <Label
                                                            variant="ghost"
                                                            color={status === USER_ACTIVE ? 'success' : 'error'}
                                                        >
                                                            {status === 3 && deletedAt && moment(deletedAt).format(DATE_FORMAT)}
                                                            {status === 2 && blockedAt && moment(blockedAt).format(DATE_FORMAT)}
                                                        </Label>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant="ghost"
                                                        color={applicationCharge === 20 ? 'default' : 'info'}
                                                    >
                                                        {applicationCharge}%
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label variant="ghost" color="info">
                                                        {moment(createdAt).format(DATE_FORMAT)}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <CreatorMoreMenu
                                                        userId={_id}
                                                        setIsLoading={setIsLoading}
                                                        isVerifiedByAdmin={isVerifiedByAdmin}
                                                        update={update}
                                                        status={status}
                                                        setUpdate={setUpdate}
                                                        isCreator={isCreator}
                                                        isFavourite={isFavourite}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 3 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {users.length === 0 && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 20]}
                        component="div"
                        count={totalDocs}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
